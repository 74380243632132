import { Select } from "antd";
import React, { useEffect, useState } from "react";
import './com-select-color.css';
import { cadServerFetch } from "@src/core/server";
import { getOptionsFromIdNameList as in2ops } from "../utils";

const ComSelectColor = ({...props}) => {
    const [item, setItem] = useState({...props});
    const [colors, setColors] = useState([]);
    const [sideColors, setSideColors] = useState();

    // Получить возможные цвета материала для профильной системы
    useEffect(() => {
        cadServerFetch(`settings/sys/ElemMat/colors?companyId=${item.companyId}&sysId=${item.sysId}&matId=${item.materialId}`)
            .then(data => setColors(data))
            .catch(() => console.log("Ошибка получения цветов материал"));
        getSideColors();
    }, []);

    // Получить цвет с вложенными палитрами
    const getSideColors = (newColorId) => {
        console.log(item);
        cadServerFetch(`settings/color/SideColors?companyId=${item.companyId}&colorId=${newColorId || item.colorId}`)
            .then(data => setSideColors(data))
            .catch(e => console.log(e.message));
    }

    const onChangeColorId = (value) => {
        const newItem = {...item, ...value};
        setItem(newItem);
        getSideColors(newItem.colorId);
        console.log(value);
        props.setItemColors && props.setItemColors({
            colorId: newItem.colorId,
            colorInId: newItem.colorInId,
            colorOutId: newItem.colorOutId,
            colorBaseId: newItem.colorBaseId,
            colorFId: newItem.colorFId
        });
    }

    const selectStyle = {width: 416};
    //console.log(sideColors);

    return<div className="com-selected-color">
        <Select placeholder="Цвет" options={in2ops(colors)} value={item.colorId} onChange={(e) => onChangeColorId({colorId: e})} style={selectStyle}/>
        {sideColors && sideColors.colorsIn 
            && <Select placeholder="Изнутри" options={in2ops(sideColors.colorsIn)} value={item.colorInId} onChange={(e) => onChangeColorId({colorInId: e})} style={selectStyle}/>}
        {sideColors && sideColors.colorsOut 
            && <Select placeholder="Снаружи" options={in2ops(sideColors.colorsOut)} value={item.colorOutId} onChange={(e) => onChangeColorId({colorOutId: e})} style={selectStyle}/>}
        {sideColors && sideColors.colorsBase 
            && <Select placeholder="Основы" options={in2ops(sideColors.colorsBase)} value={item.colorBaseId} onChange={(e) => onChangeColorId({colorBaseId: e})} style={selectStyle}/>}
        {sideColors && sideColors.colorsF 
            && <Select placeholder="Фальца" options={in2ops(sideColors.colorsF)} value={item.colorFId} onChange={(e) => onChangeColorId({colorFId: e})} style={selectStyle}/>}
    </div>
}

export default ComSelectColor;