import { Col, DatePicker, Form, Input, Row, Skeleton, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const dateFormat = 'DD.MM.YYYY';
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const OrderHead = (props:any) => {
  const { loading, item, setItem, disabled } = props;

  if (!loading)
    return (
      <Form preserve={false} colon={false} disabled={disabled}>
        <Row className="order-head-row">
          <Col span={10}>
            <Form.Item
              initialValue={item && item.docDate ? new dayjs(item.docDate) : ''} required label='Дата'
              labelCol={{span: 8}} wrapperCol={{span: 16}} name='docDate' className="order-head-row-item"
            >
              <DatePicker format={dateFormat} onChange={(data, dateString) => {setItem({ ...item, docDate: data });}} allowClear={false}/>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item initialValue={item && item.finishDate ? new dayjs(item.finishDate) : ''}
              label='Готовность' name='finishDate'
              labelCol={{span: 4}} wrapperCol={{span: 20}} className="order-head-row-item"
            >
              <DatePicker format={dateFormat} onChange={(data, dateString) => { setItem({ ...item, finishDate: data }); }} />
            </Form.Item>
          </Col>   
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item initialValue={item?.dogNo} label='Договор' name='dogNo' labelCol={{span: 8}} wrapperCol={{span: 16}} className="order-head-row-item">
              <Input onChange={(data) => { setItem({ ...item, dogNo: data.target.value }); }}/>
            </Form.Item>
          </Col> 
          <Col span={10}>
            <Form.Item initialValue={item?.addressDest} label='Адрес' name='addressDest'  labelCol={{span: 4}} wrapperCol={{span: 20}} className="order-head-row-item">
              <Input onChange={(data) => { setItem({ ...item, addressDest: data.target.value }); }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item initialValue={item?.comment} label='Комментарий' name='comment' labelCol={{span: 4}} wrapperCol={{span: 20}} className="order-head-row-item">
              <TextArea rows={2} onChange={(data) => { setItem({ ...item, comment: data.target.value });}} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
    else return (<Skeleton active />);
}

export default OrderHead;