import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Input, InputNumber, Form, Space, Select, notification } from 'antd';

import { exception, success } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IDepartment } from '@entities/department';
import { ICompany } from '@entities/company';
import { IUserSession } from '@entities/user-session';

const emptyEntity: IDepartment = {
    id: undefined,
    name: undefined,
    depno: null,
    priceCoeff: null,
    expCoeff: null,
    companyId: undefined,
};

const Department = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id } = useParams();

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entity, setEntity] = useState<IDepartment>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<ICompany>>([]);

    useEffect(() => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`departments/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения отдела', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        setLoadingCompanies(true);

        serverFetch(`companies/${userSession.companyId}`, { method: 'GET' })
            .then((data) => {
                setCompanies([data]);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компании', ex, () => d(userLoaded(undefined)));
                setLoadingCompanies(false);
            });
    }, [userSession]);

    const onFinishSave = () => {
        serverFetch(`departments`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                success(api, 'Изменения успешно сохранены');
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения отдела', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            {!loading && !loadingCompanies && (
                <Form preserve={false} colon={false} labelCol={{ span: 2 }} wrapperCol={{ span: 8 }} onFinish={onFinishSave}>
                    <Form.Item initialValue={entity?.companyId} label='Компания' name='companyId'>
                        <Select
                            disabled={true}
                            options={
                                companies
                                    ? companies.map((e) => {
                                          return { value: e.id, label: e.name };
                                      })
                                    : []
                            }
                            onChange={(value) => {
                                setEntity({ ...entity, companyId: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity?.depno}
                        required
                        label='Номер'
                        name='depno'
                        rules={[{ required: true, message: 'Укажите номер отдела' }]}
                    >
                        <InputNumber
                            min={0}
                            disabled={!userSession.isAdmin}
                            onChange={(value) => {
                                setEntity({ ...entity, depno: Number(value) });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity?.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название отдела' }]}
                    >
                        <Input
                            disabled={!userSession.isAdmin}
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity?.priceCoeff}
                        required
                        label='Коэфф. цены поставки'
                        name='priceCoeff'
                        rules={[{ required: true, message: 'Укажите коэфф. цены поставки' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            min={0}
                            onChange={(value) => {
                                setEntity({ ...entity, priceCoeff: Number(value) });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity?.expCoeff}
                        required
                        label='Коефф. цены продажи'
                        name='expCoeff'
                        rules={[{ required: true, message: 'Укажите коефф. цены продажи' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            min={0}
                            onChange={(value) => {
                                setEntity({ ...entity, expCoeff: Number(value) });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            {userSession.isAdmin && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Department;
