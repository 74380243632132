import React from "react";
import "./cad-toolbar.css"
import {Tooltip} from "antd";
import { ImgGetRefresh, ImgGetSplitHor, ImgGetSplitVert, ImgGetMosk, ImgGetAlignGeom, ImgGetAlignLight, ImgGetPropElem,
    ImgGetPropSoed, ImgGetDel, ImgGetPropStv, ImgGetAddStv, 
    ImgGetUndo} from './images';
import ImgGetClose from "./images/img-get-close";

const CadToolbar = ({...props}) => {

    const { onRefresh, onUndo, onClose, onInsertHorizSplit, onInsertVertSplit, onInsertSash, onInsertMosk, onViewSash, onDelete } = props;
    const getButton = (item) => {
        const className = item.disabled ? "cad-tool-button-disabled" : "cad-tool-button"
        return <Tooltip title={item.hint} placement="right"><div className={className} key={item.id} onClick={item.disabled ? null : item.onClick}>
            {item.img}
            {item.title && <div className="cad-tool-label">{item.title}</div>}
            </div></Tooltip>}

    return<div className="cad-toolbar">
        {getButton({id:0, hint: "Закрыть", img: <ImgGetClose />, onClick: onClose})}
        {getButton({id:1, hint: "Обновить", img: <ImgGetRefresh />, onClick: onRefresh})}
        {getButton({id:12, hint: "Отменить", img: <ImgGetUndo />, onClick: onUndo})}
        {getButton({id:3, hint: "Разделить горизонтальным разделителем", img: <ImgGetSplitHor />, onClick: onInsertHorizSplit})}
        {getButton({id:4, hint: "Разделить вертикальным разделителем", img: <ImgGetSplitVert />, onClick: onInsertVertSplit})}
        {getButton({id:5, hint: "Москитная сетка", img: <ImgGetMosk />, onClick: onInsertMosk })}
        {getButton({id:6, hint: "Уравнивание по световому проему", img: <ImgGetAlignLight disabled/>, disabled: true })}
        {getButton({id:7, hint: "Уравнивание по геометрии", img: <ImgGetAlignGeom disabled/>, disabled: true})}
        {getButton({id:8, hint: "Вставить створку", img: <ImgGetAddStv />, onClick: onInsertSash})}
        {getButton({id:9, hint: "Свойства створки", img: <ImgGetPropStv />, onClick: onViewSash})}
        {getButton({id:10, hint: "Свойства элемента", img: <ImgGetPropElem disabled/>, disabled: true})}
        {getButton({id:11, hint: "Свойства соединения", img: <ImgGetPropSoed disabled/>, disabled: true})}
        {getButton({id:12, hint: "Удалить", img: <ImgGetDel />, onClick: onDelete})}
    </div>
}

export default CadToolbar;