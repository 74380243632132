import React from "react";
import { Group, Line, Text } from "react-konva";
import { getKanvaPoints, getSizeText } from "./utils";

const strokeWidth = 1;
const strokeColor = 'black';

export const CadSizeX = ({...props}) => {
    const {item, size } = props;
    const dy = -item.level * size;
    // console.log(item);
    const leftLine   = [{ x:item.startValue, y: dy }, { x:item.startValue, y:dy -size }]
    const rightLine  = [{ x:item.endValue, y: dy }, { x:item.endValue, y: dy -size }]
    const bottomLine = [{ x:item.startValue, y:-size*0.9 + dy }, { x:item.endValue, y:-size*0.9 + dy }]
    const leftArrow  = [{ x:item.startValue+size/2 , y:-size + dy }, { x:item.startValue, y:-size*0.9 + dy }, { x:item.startValue+size/2, y:-size*0.8 + dy }]
    const rightArrow = [{ x:item.endValue-size/2 , y:-size + dy }, { x:item.endValue, y:-size*0.9 + dy }, { x:item.endValue-size/2, y:-size*0.8 + dy }]
    return <Group>
        <Line points={getKanvaPoints(leftLine)} strokeWidth={strokeWidth} stroke={strokeColor}/>
        <Line points={getKanvaPoints(rightLine)} strokeWidth={strokeWidth} stroke={strokeColor}/>
        <Line points={getKanvaPoints(bottomLine)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Line points={getKanvaPoints(leftArrow)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Line points={getKanvaPoints(rightArrow)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Text 
            text={getSizeText(item.userValue)} fontSize={size*0.8} x={item.startValue} y={-size*0.1 + dy} scaleY={-1} 
            align="center" width={item.endValue-item.startValue}
            />
    </Group>
}

export const CadSizeY = ({...props}) => {
    const {item, size, x0 } = props;
    const x = x0 + (item.level + 1) * size;
    // console.log(item.startValue, item.endValue, x );
    const leftLine  = [{ x, y: item.startValue }, { x: x - size, y: item.startValue }]
    const rightLine  = [{ x, y: item.endValue }, { x: x - size, y: item.endValue }]
    const bottomLine  = [{ x: x-0.1*size , y: item.startValue }, { x: x-0.1*size, y: item.endValue }]
    const leftArrow = [{ x: x , y: item.startValue + size*0.5 }, { x: x-0.1*size , y: item.startValue }, { x: x-0.2*size , y: item.startValue + size*0.5 }]
    const rightArrow = [{ x: x , y: item.endValue - size*0.5 }, { x: x-0.1*size , y: item.endValue }, { x: x-0.2*size , y: item.endValue - size*0.5 }]
    return <Group>
        <Line points={getKanvaPoints(leftLine)} strokeWidth={strokeWidth} stroke={strokeColor}/>
        <Line points={getKanvaPoints(rightLine)} strokeWidth={strokeWidth} stroke={strokeColor}/>
        <Line points={getKanvaPoints(bottomLine)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Line points={getKanvaPoints(leftArrow)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Line points={getKanvaPoints(rightArrow)} strokeWidth={strokeWidth} stroke={strokeColor} />
        <Text 
            text={getSizeText(item.userValue)} fontSize={size*0.8} x={x-0.9*size} y={item.startValue} scaleY={-1} 
            align="center" rotation={90} width={item.endValue-item.startValue}
            />
    </Group>
}