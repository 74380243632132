import { Select } from "antd";
import React, { useEffect, useState } from "react";
import './com-select-color.css';
import { cadServerFetch } from "@src/core/server";

const ComSelectZapoln = ({ companyId, onSelectZapoln }) => {
    const [zapolns, setZapoln] = useState([]); //Все доступные
    const [selectedOption, setSelectedOption] = useState(null);

    const onSelect = (value, option) => {
        setSelectedOption(value);
        const selectedZap = zapolns.find(item => item.id === value);
        onSelectZapoln(selectedZap.cnts);
        setSelectedOption(null);
    }

    useEffect(() => {
        cadServerFetch(`settings/Zapolns?companyId=${companyId}`)
            .then(data => setZapoln(data))
            .catch(() => console.log("Ошибка получения заполнений"));
    }, []); 

    const zapolnOptions = zapolns.map(i => ({value: i.id, label: i.name }));
    
return<div className="com-selected-color">
            <div className="oper-props-item-select">
                <Select
                    style={{width: 416}}
                    value={selectedOption} 
                    options={zapolnOptions} 
                    onSelect={onSelect} 
                    notFoundContent="Введите текст для поиска по имени"/>
            </div>
</div>
}

export default ComSelectZapoln;
