import React from "react";

const ImgGetDel = ({...props}) => {
  const {disabled} = props;  
  return<svg width="32" height="32">
    <rect className={disabled ? "svg-cons-disabled" : "svg-cons"} rx="4" id="svg_2" height="28" width="28" y="2" x="2" fill="#fff"/>
    <g className={disabled ? "svg-select-disabled" : "svg-select"}>
      <line stroke-linecap="round" stroke-linejoin="null" y2="30" x2="16" y1="2" x1="16" fill="none"/>
      <line stroke-linecap="round" stroke-linejoin="null" y2="20" x2="11" y1="11" x1="21" fill-opacity="null" fill="none" />
      <line stroke-linecap="round" stroke-linejoin="null" y2="20" x2="21" y1="11" x1="11" fill-opacity="null" fill="none"/>
    </g>
  </svg>
}

export default ImgGetDel;