import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MainLayout from '@src/main-layout';
import Auth from '@components/auth/auth';
import Companies from '@components/companies/companies';
import Company from '@components/companies/company';
import Users from '@components/users/users';
import CurrencyRates from '@components/currency-rates/currency-rates';
import Dictionaries from '@components/dictionaries/dictionaries';
import Departments from '@components/departments/departments';
import Department from '@components/departments/department';
import Prices from '@components/prices/prices';
import Orders from '@components/orders/orders';
import Order from '@components/orders/order';
import { IUserSession } from '@entities/user-session';
import { useAppSelector } from '@store/hooks';
import { UserLevel } from '@enums/user-level';
import '@src/core/index.css';
import Cad from './cad/cad';

declare global {
    interface Window {
        accessToken: string;
    }
}

const App = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    let navigateTo = '/Orders';
    //let navigateTo = '/companies';
    if (userSession?.level === UserLevel.Manufacturer) {
        navigateTo = `/company/${userSession.companyId}`;
    } else if (userSession?.level === UserLevel.Dealer) {
        navigateTo = `/department/${userSession.depId}`;
    }

    return userSession ? (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<Navigate replace to={navigateTo} />} />
                <Route path='orders' element={<Orders />} />
                <Route path='companies' element={<Companies />} />
                <Route path='company/:id' element={<Company />} />
                <Route path='departments' element={<Departments />} />
                <Route path='department/:id' element={<Department />} />
                <Route path='users' element={<Users />} />
                <Route path='prices' element={<Prices />} />
                <Route path='currencyrates' element={<CurrencyRates />} />
                <Route path='dictionaries' element={<Dictionaries />} />
                <Route path='orders/new' element={<Order />} />
                <Route path='orders/:id' element={<Order />} />
            </Route>
        </Routes>
    ) : (
        <Auth />
    );
};

export default App;
