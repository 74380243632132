import { Dayjs } from 'dayjs';
import { IOrderItem } from '@entities/order-item';
import { SyncStatus } from '../enums/sync-status';

export interface IOrder {
    id: number;
    docDate: Dayjs | null; // DateTime
    finishDate: Dayjs | null; //DateTime
    ordno: number;
    syma1: number;
    syma2: number;
    syma3: number;
    clientId: number | undefined;
    companyId: number | undefined;
    depId: number | undefined;
    addressDest: string;
    comment: string;
    statusId: number | undefined;
    payment2: number;
    payment3: number;
    dogNo: string;
    userId: number;
    createdOn: Dayjs; // DateTime
    items: Array<IOrderItem> | undefined;
    clientName: string;
    companyName: string;
    depName: string;
    userName: string;
    statusName: string;
    syncStatus: SyncStatus;
}

export const getEmptyOrder = (docDate:Dayjs):IOrder  => ({
    id: 0,
    docDate: docDate,
    finishDate: null,
    ordno: 0,
    syma1: 0,
    syma2: 0,
    syma3: 0,
    clientId: undefined,
    companyId: undefined,
    depId: undefined,
    addressDest: '',
    comment: '',
    statusId: undefined,
    payment2: 0,
    payment3: 0,
    dogNo: '',
    userId: 0,
    createdOn: docDate,
    items: new Array<IOrderItem>(),
    clientName: '',
    companyName: '',
    depName: '',
    userName: '',
    statusName: '',
    syncStatus: SyncStatus.None,
})
