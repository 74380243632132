export enum OrderParts {
    /// <summary>
    /// Заказ
    /// </summary>
    Order = 1,

    /// <summary>
    /// Изделие
    /// </summary>
    Product = 2,

    /// <summary>
    /// Доп
    /// </summary>
    Extra = 3,

    /// <summary>
    /// Позиция заказа
    /// </summary>
    OrderPosition = 4,
}

export const enumLabel = (value?: OrderParts): string => {
    switch (value) {
        case OrderParts.Order:
            return 'Заказ';
        case OrderParts.Product:
            return 'Изделие';
        case OrderParts.Extra:
            return 'Доп';
        case OrderParts.OrderPosition:
            return 'Позиция заказа';
        default:
            return '';
    }
};
