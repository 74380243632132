import React from "react";
import { Group, Line } from "react-konva";
import { getKanvaPoints } from "./utils";
import useImage from 'use-image';

const CadElem = ({...props}) => {
    const {elem, onSelect, selections, scale, onDragStart, onDragFinish, onDblClick, onEnter } = props;
    const [image] = useImage('/images/stick.png');

    const divCons = elem.paintConses && elem.paintConses.map((c, idx) => 
        {
            const fillData = selections.indexOf(elem.id) === -1 || !c.closed ? { fill: c.brush, fillPatternScale: {x: 1, y: 1} } : { fillPatternImage: image, fillPatternScale: { x: 0.5/scale, y: 0.5/scale } }
            return c.coords && <Line key={`c${elem.id}_${idx}`}
                points={getKanvaPoints(c.coords)}
                strokeWidth={1}
                closed={c.closed}
                stroke={c.pen}
                objId={elem.id}
                {...fillData}
                opacity={elem.vid === 2 ? 0.9 : 1}
            />})
            
    return elem && <Group 
        id={`${elem.id}`}
        key={elem.id}
        objId={elem.id}
        name={`${elem.id}`}
        onClick={(e) => {
            //console.log(e);
            onSelect(e.target.attrs.objId, e.evt.shiftKey);
        }}        
        draggable
        onDragStart={(e) => { onDragStart(e.target); }}
        onDragEnd={(e) => { onDragFinish(e.target); }}
        onDblClick={(e) => onDblClick(e.target)}
        onMouseEnter={(e) => onEnter(e.target)}
        x={0}
        y={0}
    >
        {divCons}
    </Group>
}

export default CadElem;