export interface IEnumItem {
    value: number;
    label: string;
}

export const getEnumList = (enums: any, getLabel: Function) => {
    let list: Array<IEnumItem> = [];
    Object.values(enums).map((key) => {
        if (!isNaN(Number(key))) {
            list.push({ label: getLabel ? getLabel(key) : key, value: Number(key) });
        }
    });

    return list;
};
