import React from "react";

const ImgGetRefresh = () => {
    return<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 2L13 3.99545L12.9408 4.05474M13 18.0001L11 19.9108L11.0297 19.9417M12.9408 4.05474 L11 6
        M12.9408 4.05474C12.6323 4.01859 12.3183 4 12 4
        C7.58172 4 4 7.58172 4 12
        C4 14.5264 5.17107 16.7793 7 18.2454M17 5.75463C18.8289 7.22075 20 9.47362 20 12
        C20 16.4183 16.4183 20 12 20
        C11.6716 20 11.3477 19.9802 11.0297 19.9417M13 22.0001L11.0297 19.9417"
        stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
        className="svg-select" />
    </svg>
}
export default ImgGetRefresh;