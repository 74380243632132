import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message, notification } from 'antd';
import { Input, Form, Button } from 'antd';
import jwt_decode from 'jwt-decode';
import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { IUserSession } from '@entities/user-session';
import { serverFetch } from '@src/core/server';
import './auth.css';

const Auth = () => {
    const d = useDispatch();
    const [signInForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    //const [register, setRegister] = useState<boolean>(false);
    const [sentCode, setSentCode] = useState<boolean>(false);
    const [data, setData] = useState<any>({email: '', register: false, registerToken: '', code: ''})

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            close();
        };
    }, []);

    const onKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            signInForm.submit();
        }
    };

    const close = () => {
        setLoading(false);
        signInForm.resetFields();
    };

    const onFinish = (entity: any) => {
        setLoading(true);
        let data = { login: entity.signInLogin, password: entity.password };
        serverFetch(`auth/signin`, { method: 'POST', bodyData: data, credentials: 'include' })
            .then((data) => {
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                var result = jwt_decode(data.accessToken) as IUserSession;
                let userSession: IUserSession = {
                    userId: result.userId,
                    email: result.email,
                    name: result.name,
                    companyId: result.companyId,
                    depId: result.depId,
                    isAdmin: result.isAdmin,
                    level: result.level,
                };
                d(userLoaded(userSession));
                close();
            })
            .catch((ex: any) => {
                setLoading(false);
                exception(api, 'Ошибка авторизации', ex, () => d(userLoaded(undefined)));
            });
    };

    
    const onSendCode = () => {
        setLoading(true);
        serverFetch('auth/sendcode', {method: 'POST', bodyData: data})
            .then(() => {
                setSentCode(true);
                setLoading(false);
            })
            .catch(e => {
                message.error(e.message);
                setLoading(false);
            });
    }

    const onChangeValue = (value:any) => {
        setData({...data, ...value});
    }

    const onLogin = () => {
        setLoading(true);
        serverFetch('auth/checkcode', {method: 'POST', bodyData: data})
            .then((data) => {
                setLoading(false);
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                var result = jwt_decode(data.accessToken) as IUserSession;
                let userSession: IUserSession = {
                    userId: result.userId,
                    email: result.email,
                    name: result.name,
                    companyId: result.companyId,
                    depId: result.depId,
                    isAdmin: result.isAdmin,
                    level: result.level,
                };
                d(userLoaded(userSession));
                close();
            })
            .catch(e => {
                message.error(e.message);
                setLoading(false);
            });
    }

    return (
        <div>
            <div className='auth-body'>
                <div className='auth-container'>
                    <div className='auth-title'>ИТ Окна</div>
                    <div className='auth-form'>
                        <div className='auth-form-title'>{data.register ? 'РЕГИСТРАЦИЯ' : 'ВХОД'}</div>
                        <Form size={'large'} form={signInForm} initialValues={{ remember: true }} onFinish={onFinish}>
                            {data.register && <Form.Item className='auth-form-item'>
                                <Input name='registerToken' placeholder='Код поставщика' disabled={sentCode || loading} className='auth-form-item-input' onChange={((e) => onChangeValue({registerToken: e.target.value}))}/>
                            </Form.Item>}
                            <Form.Item className='auth-form-item' name="email">
                                <Input placeholder='Почта' disabled={loading || sentCode} className='auth-form-item-input' onChange={(e) => onChangeValue({email: e.target.value})}/>
                            </Form.Item>
                            {sentCode || <Form.Item style={{ marginBottom: 0 }}>
                                <Button type='primary' loading={loading} className='auth-form-btn' onClick={onSendCode}>Отправить код</Button>
                            </Form.Item>}
                            {sentCode && <Form.Item className='auth-form-item'>
                                <Input placeholder='Введите код' disabled={loading} className='auth-form-item-input' onChange={(e) => onChangeValue({code: e.target.value})}/>
                            </Form.Item>}
                            {sentCode && <Form.Item style={{ marginBottom: 0 }}>
                                <Button type='primary' loading={loading} className='auth-form-btn' onClick={onLogin}>{data.register ? 'Зарегистрировать' : 'Войти'}</Button>
                            </Form.Item>}
                        </Form>
                    </div>
                    {data.register || <div className='auth-create'>
                        Нет учетной записи? <span className='auth-create-btn' onClick={() => onChangeValue({register: true})}>Создать</span>
                    </div>}
                </div>
            </div>
            {contextHolder}
        </div>
    );
};

export default Auth;