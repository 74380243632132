import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { Modal, Table, Button, notification, message, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, DeleteFilled, PlusOutlined, QuestionCircleOutlined, CheckOutlined, SendOutlined } from '@ant-design/icons';
import Toolbar, { ICommand } from '@controls/toolbar/toolbar';
import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { cadServerFetch, serverFetch } from '@src/core/server';
import { IOrder } from '@entities/order';
import { IUserSession } from '@entities/user-session';
import { getSyncStatusColor, getSyncStatusName, SyncStatus } from '@src/core/enums/sync-status';
import { fmtDate } from '@src/core/extensions/utils';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Orders = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();
    const d = useAppDispatch();
    const [entities, setEntities] = useState<Array<IOrder>>([]);
    const [currentEntity, setCurrentEntity] = useState<IOrder>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;
        setRefreshRequired(false);
        setCurrentEntity(undefined);
        setEntities([]);
        setLoading(true);
        serverFetch('orders', { method: 'GET', queryParams: { companyId: userSession.companyId, depId: userSession.depId } })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения заказов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (openDialog) currentEntity && navigate(`${resolved.pathname}/${currentEntity.id}`);
    }, [openDialog]);

    const onDelete = (id: number) => {
        serverFetch(`orders/${id}`, { method: 'DELETE' })
            .then(() => setRefreshRequired(true))
            .catch((ex) => {
                message.error('Ошибка удаления заказа');
                console.error(ex.message);
            });
    };

    const onSend = (id:number) => {
        serverFetch(`orders/send/${id}`, { method: 'PUT' })
            .then(() => setRefreshRequired(true))
            .catch((ex) => {
                message.error('Ошибка отправки заказа');
                console.error(ex.message);
            });
    }

    const renderToolbar = () => {
        let commands: Array<ICommand> = [
            {
                label: 'Обновить',
                key: 'refresh',
                disabled: loading,
                icon: <ReloadOutlined />,
                onClick: () => setRefreshRequired(true),
            },
        ];

        if (userSession.isAdmin || true) {
            commands.push({
                label: 'Добавить',
                key: 'add',
                type: 'primary',
                icon: <PlusOutlined />,
                onClick: () => navigate(`${resolved.pathname}/new`),
            });
        }

        return <Toolbar commands={commands} />;
    };

    const getButtonSend = (record:IOrder) => {
        //const disabled = record.syncStatus == SyncStatus.Ok || record.syncStatus == SyncStatus.Wait;
        let style = {};
        switch (record.syncStatus){
          case SyncStatus.None: style = { color: '#898989', cursor: 'handle' }; break;
          case SyncStatus.Error: style = { color: 'red' }; break;
          case SyncStatus.Ok: style = { color: 'green' }; break;
          case SyncStatus.Wait: style = { color: '#ffdd2d' };break;
        }
        return <Button type='text' shape='circle' icon={<SendOutlined style={style}/>}
            onClick= {(e) => {
                e.stopPropagation();
                if (record.syncStatus == SyncStatus.Ok || record.syncStatus == SyncStatus.Wait) return;
                Modal.confirm({
                    title: `Отправить заказ "${record?.id}"?`,
                    okType: 'primary',
                    icon: <QuestionCircleOutlined />,
                    okText: 'ОК',
                    cancelText: 'Отмена',
                    onOk: () => {
                        record.id && onSend(record.id);
                    },
                });
            }}
        />
    }

    const renderTable = () => {
        const columns: ColumnsType<IOrder> = [
            { title: '#', dataIndex: 'id', width: 100, },
            // { title: '№', dataIndex: 'ordno', width: 50, },
            { title: 'Отдел', dataIndex: 'depName', width: 150, },
            { title: 'Дата', dataIndex: 'docDate', width: 150, render: (_, record) => record.docDate && fmtDate(dayjs(record.docDate)), align: 'center' },
            { title: 'Готовность', dataIndex: 'finishDate', width: 150, render: (_, record) => record.finishDate && fmtDate(dayjs(record.finishDate)), align: 'center' },
            { title: 'Статус', dataIndex: 'statusName', width: 100, render: (_, record) => <Tag color={getSyncStatusColor(record.syncStatus)}>{getSyncStatusName(record.syncStatus)}</Tag>, align: 'center'},
            { title: 'Договор', dataIndex: 'dogNo', width: 100, },
            { title: 'Сумма', dataIndex: 'syma2', width: 100, align: "right"},
            //{ title: 'Отпуск', dataIndex: 'syma3', width: 100, },
            { title: 'Сотрудник', dataIndex: 'userName', width: 150, },
            //{ title: 'Клиент', dataIndex: 'clientName', width: 150, },
            { title: '', dataIndex: '', },
        ];

        if (userSession.isAdmin || false) {
            columns.splice(0, 0, {
                align: 'center',
                width: 76,
                render: (_, record) => {
                    return (<div className='orders-col-actions'>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();
                                Modal.confirm({
                                    title: `Удалить заказ "${record?.id}"?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                        {getButtonSend(record)}
                        </div>
                    );
                },
            });
        };

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: IOrder) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;
                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}
            {contextHolder}
        </>
    );
};

export default Orders;