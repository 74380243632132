// InsertSplit.js
import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Tabs, Button, Input, message } from "antd";
import { cadServerFetch } from "@src/core/server";
import CadSplitFirstTab from './cad-split-first-tab';
import CadSplitSecondTab from "./cad-split-second-tab";
import CadSplitThirdTab from "./cad-split-third-tab";
import { calculateDivisionPoints } from "./cad-split-calc";
import ComSelectColorSplit from "../com-select-split-color";

const orient = new Map([
    [1, "Импост"],
    [2, "Стойка"],
    [3, "Деление контуров"]
]);

const gab = new Map([
    [1, "Геометрически"],
    [2, "По световому проему"],
]);

const multTypes = new Map([
    [1, "Равномерно"],
    [2, "С шагом от левого края"],
    [3, "С шагом от правого края"],
    [4, "С шагом от середины"]
]);

const CadSplit = ({ onCancel, orientation, elems, companyId, sysId, constructionId, width, height, setConstruction, onClose }) => {
    const [form] = Form.useForm(); 
    const [activeTab, setActiveTab] = useState('1');
    const [coordinate, setCoordinate] = useState(''); //Вводимая пользователем координата
    const [coordinatesList, setCoordinatesList] = useState([]); //Список введенных координат
    const [selectedCoordinate, setSelectedCoordinate] = useState(null); //Активный (выбранный) элемент списка координат
    const [split, setSplit] = useState(); 
    const [materials, setMaterials] = useState([]); //Все доступные материалы
    const [defMat, setDefMat] = useState([]); //Материал по умолчанию
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false); //Готова ли форма к отправке (флаг)
    const [straightOrient, setStraightOrient] = useState(null); //Горизонтально - 1, Вертикально - 2 
    const [materialId, setMaterialId] = useState(0); //ID выбранного материала
    const [colorId, setColorId] = useState(0); //ID выбранного цвета
    const [sampleSplit, setSampleSplit] = useState({
        companyId: companyId,
        constructionId: constructionId,
        elems: elems,
        kind: orientation,
        materialId: 0,
        colorId: 0,
        colorInId: 0,
        colorOutId: 0,
        colorBaseId: 0,
        colorFId: 0,
        segments: []
    }); //Макет запроса для разделения
    const [points, setPoints] = useState({
        Point1X: straightOrient === 1 ? 0 : width / 2,
        Point2X: straightOrient === 1 ? width : width / 2,
        Point1Y: straightOrient === 1 ? height / 2 : 0,
        Point2Y: straightOrient === 1 ? height / 2 : height,
    }); //Конструктор для точек 
    const [angle, setAngle] = useState(0); //Угол поворота
    const [pointToCalcValue, setPointToCalcValue] = useState(2); //Точка отсчета для угла

    const [gabCalc, setGabCalc] = useState(1); //Габариты: 1 - геометрия, 2 - по световому
    const [divisionType, setDivisionType] = useState(1); //Тип разделения: Равномерно, шаг слева, шаг справа, шаг от центра
    const [leftMargin, setLeftMargin] = useState(0);
    const [rightMargin, setRightMargin] = useState(0);
    const [step, setStep] = useState(0);
    const [quantity, setQuantity] = useState(1);

    const validateXCoordinate = (x) => x >= 0 && x <= width;
    const validateYCoordinate = (y) => y >= 0 && y <= height;

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        cadServerFetch(`Cad/Oper/Split?elems=${elems}&orientation=${orientation}&companyId=${companyId}&constructionId=${constructionId}`)
            .then((data) => {
                if (!data) return;
                
                setSplit(data);
                setDefMat(data.matDefault);
                const uniqueMats = Array.from(new Set(data.mats.map(mat => mat.id))).map(id => {
                    const mat = data.mats.find(m => m.id === id);
                    return { value: mat.id, label: `${mat.art} ${mat.name}`, name: mat.name, art: mat.art };
                });
                setMaterials(uniqueMats);
                setStraightOrient(orientation);
                setSampleSplit((prevState) => ({
                    ...prevState,
                    materialId: data.matDefault?.id
                }));
                setCoordinate(orientation === 1 ? height / 2 : width / 2);
                setAngle(orientation === 1 ? 0 : 90)
                console.log(orientation)
            })
            .catch(() => console.log("Ошибка получения базовой информации для разделения"));
    }, [elems, orientation, companyId, constructionId]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, []);

    useEffect(() => {
        setCoordinatesList([]);
        //message.info(`Активная вкладка: ${activeTab}`);
    }, [activeTab]);

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            form.submit();
        }
    };

    useEffect(() => {
        setSampleSplit((prevState) => ({
            ...prevState,
            materialId: materialId
        }));
    }, [materialId]);

    useEffect(() => {
        setSampleSplit((prevState) => ({
            ...prevState,
            colorId: colorId
        }));
    }, [colorId]);

    useEffect(() => {
        console.log('Изменен sample split:', sampleSplit)
    }, [sampleSplit]);

    useEffect(() => {
        console.log('Изменены отступы:', leftMargin + " " + rightMargin)
        let res = validateArea();
        if (!res) {
            message.warning('Отступы слишком велики!');
        }
    }, [leftMargin, rightMargin]);

    const handleAddSegments = () => {
        let newStartPoints = [];
        let newEndPoints = [];

        if (coordinatesList.length > 0) {
            coordinatesList.forEach(coord => {
                if (straightOrient === 1 && validateYCoordinate(coord)) {
                    newStartPoints = newStartPoints.concat([{ x: 0, y: coord }]);
                    newEndPoints = newEndPoints.concat([{ x: width, y: coord }]);
                }
                if (straightOrient === 2 && validateXCoordinate(coord)) {
                    newStartPoints = newStartPoints.concat([{ x: coord, y: 0 }]);
                    newEndPoints = newEndPoints.concat([{ x: coord, y: height }]);
                }
            });
        } else {
            if (straightOrient === 1) {
                if (coordinate === "") {
                    newStartPoints = newStartPoints.concat([{ x: 0, y: height / 2 }]);
                    newEndPoints = newEndPoints.concat([{ x: width, y: height / 2 }]);
                } else {
                    newStartPoints = newStartPoints.concat([{ x: 0, y: coordinate }]);
                    newEndPoints = newEndPoints.concat([{ x: width, y: coordinate }]);
                }
            } else {
                if (coordinate === "") {
                    newStartPoints = newStartPoints.concat([{ x: width / 2, y: 0 }]);
                    newEndPoints = newEndPoints.concat([{ x: width / 2, y: height }]);
                } else {
                    newStartPoints = newStartPoints.concat([{ x: coordinate, y: 0 }]);
                    newEndPoints = newEndPoints.concat([{ x: coordinate, y: height }]);
                }
            }
        }

        setSampleSplit(prevState => ({
            ...prevState,
            segments: newStartPoints.map((startPoint, index) => ({
                pointStart: startPoint,
                pointFinish: newEndPoints[index],
                height: 0,
                shape: {}
            }))
        }));

        setIsReadyToSubmit(true);
    };

    useEffect(() => {
        if (isReadyToSubmit) {
            cadServerFetch(`cad/oper/split`, { method: 'POST', bodyData: sampleSplit })
                .then((data) => {
                    setConstruction(data);
                    onClose(true);
                })
                .catch((ex) => {
                    message.error(`Ошибка вставки разделения: ${ex.message}`);
                });
            setIsReadyToSubmit(false);
        }
    }, [isReadyToSubmit, sampleSplit]);

    const validateArea = () => {
        let area = (straightOrient === 1 ? height : width);
        area -= (leftMargin + rightMargin);
        console.log(area)
        if (area > 0 )
            return true
        else
            return false        
    }

    const onSplit = () => {
        if (Number(activeTab) === 1) {
            handleAddSegments();
        } else if (Number(activeTab) === 2) {
            setSampleSplit(prevState => ({
                ...prevState,
                segments: [
                    {
                        pointStart: { x: points.Point1X, y: points.Point1Y },
                        pointFinish: { x: points.Point2X, y: points.Point2Y },
                        height: 0,
                        shape: {}
                    }
                ]
            }));
            setIsReadyToSubmit(true);
        } else if (Number(activeTab) === 3) {
            if (validateArea()) {
                console.log(divisionType !== 1);
                console.log(step === 0);
                if (!((divisionType !== 1) && (step === 0))) {
                    const calcPoints = calculateDivisionPoints(straightOrient, height, width, leftMargin, rightMargin, step, 
                        divisionType, quantity
                    );
                    setSampleSplit(prevState => ({
                        ...prevState,
                        segments: calcPoints
                    }));
                    setIsReadyToSubmit(true);
                } else {
                    message.warning('Шаг не может быть нулевым!');
                }
            } else {
                console.log(rightMargin);
                message.warning('Отступы слишком велики!');
            }
        }
    };

    if (!split) {
        return null;
    }    

    const orientOptions = Array.from(orient.entries()).map(([value, label]) => ({ value, label }));
    const gabOptions = Array.from(gab.entries()).map(([value, label]) => ({ value, label }));
    const multTypesOptions = Array.from(multTypes.entries()).map(([value, label]) => ({ value, label }));

    const initialValues = {
        OrientSplit: orientation,
        Mat: defMat ? defMat.art + " " + defMat.name : undefined,
        Orientation: 1,
        InputCoord: straightOrient === 1 ? height / 2 : width / 2,
        Point1X: parseFloat(points.Point1X).toFixed(1),
        Point2X: parseFloat(points.Point2X).toFixed(1), 
        Point1Y: parseFloat(points.Point1Y).toFixed(1),
        Point2Y: parseFloat(points.Point2Y).toFixed(1),
        PointToCalc: pointToCalcValue,
        AngleRotate: parseFloat(angle).toFixed(1),
        CalculationType: gabCalc,
        DivisionType: divisionType,
        margLeft: parseFloat(leftMargin).toFixed(1),
        margRight: parseFloat(rightMargin).toFixed(1),
        step: parseFloat(step).toFixed(1),
        quantity: quantity
    };

    return (
        <Modal open={true} onCancel={onCancel} forceRender closable={false} footer={null} getContainer={false} width={700} title="Вставка разделения">
            <Form colon={false} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} size="small" initialValues={initialValues} onFinish={onSplit}>
                <Form.Item label="Вид разделения" name="Orientation">
                    <Select options={orientOptions} />
                </Form.Item>
                <Form.Item label="Материал" name="Mat">
                    <Select className="input-disabled" options={materials}
                        onChange={(value) => {
                            setMaterialId(value);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Цвет" name="Color">
                    <ComSelectColorSplit item={sampleSplit} sysId={sysId} setItem={setSampleSplit} />
                </Form.Item>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <Tabs.TabPane tab="Прямое" key="1">
                        <CadSplitFirstTab
                            orientation={orientation} width={width} height={height}
                            coordinate={coordinate} setCoordinate={setCoordinate} straightOrient={straightOrient}
                            setStraightOrient={setStraightOrient} coordinatesList={coordinatesList} setCoordinatesList={setCoordinatesList}
                            selectedCoordinate={selectedCoordinate} setSelectedCoordinate={setSelectedCoordinate}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Наклон" key="2">
                        <CadSplitSecondTab
                                form={form} width={width} height={height} points={points} setPoints={setPoints}
                                angle={angle} setAngle={setAngle} pointToCalcValue={pointToCalcValue}
                                setPointToCalcValue={setPointToCalcValue}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Несколько" key="3">
                        <CadSplitThirdTab
                            leftMargin={leftMargin} setLeftMargin={setLeftMargin} rightMargin={rightMargin}
                            setRightMargin={setRightMargin} quantity={quantity} setQuantity={setQuantity}
                            step={step} setStep={setStep} divisionType={divisionType} setDivisionType={setDivisionType}
                            gabOptions={gabOptions} multTypesOptions={multTypesOptions}
                        />
                    </Tabs.TabPane>
                </Tabs>
                <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
                    <Button type="primary" onClick={onSplit}>Разделить</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CadSplit;
