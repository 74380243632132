import { Modal, Form, InputNumber, Input, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { serverFetch } from "@src/core/server";

const OrdersEdit = ({...props}) => {
    const { onCancel, id, onClose} = props;
    const [orderProperties, setOrdersProperties] = useState({
        id: id,
        qty: null,
        name: null,
        comment: null
    });
    const [ordedInitialProp, setOrderInitialProp] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        serverFetch(`Orders/item/${id}`, { method: 'GET' })
        .then((data) => {
            if (!data) return;
            const initialValues = {
                id: id,
                qty: data.qty,
                name: data.name,
                comment: data.comment
            };
            setOrdersProperties(initialValues);
            setOrderInitialProp(data);
        })
        .catch(() => console.log("Ошибка получения информации об элементе заказа"));
    }, [id]); 

    const onEditOrderElem = () => {
        form.validateFields()
        .then(() => {
            serverFetch(`Orders/item`, { method: 'PUT', bodyData: orderProperties})
            .then((data) => {
                onClose(true);
            })
            .catch((ex) => {
                message.error(`Ошибка изменения элемента заказа: ${ex.message}`);
            });
        })
        .catch(() => {
            message.error('Убедитесь, что значение количества корректно.');
        });
    };

    if (!ordedInitialProp) {return null;}

    return (
        <Modal open={true} onCancel={onCancel} onFinish={onEditOrderElem} forceRender closable={false} footer={null} getContainer={false} width={800} title={`Изделие #${id}`}>
            <Form form={form} initialValues={{qty: orderProperties.qty, name: orderProperties.name, comment: orderProperties.comment}}>
                <Form.Item style={{ marginLeft: '43px' }} label="Количество" name="qty" colon={false}
                rules={[{ required: true, message: 'Введите количество.' }, { type: 'number', min: 1, message: 'Количество должно быть больше 0.' }]}>
                    <InputNumber style={{width: "20%"}} onChange={(value) => setOrdersProperties((prevState) => ({...prevState, qty: value }))}/>
                </Form.Item>
                <Form.Item label="Наименование" name="name" style={{ marginBottom: 16, marginLeft: '32px' }} colon={false}>
                    <Input onChange={(event) => setOrdersProperties((prevState) => ({...prevState, name: event.target.value }))} />
                </Form.Item>
                <Form.Item label="Комментарий" name="comment" style={{ marginBottom: 16 , marginLeft: '39px'}} colon={false}>
                    <Input onChange={(event) => setOrdersProperties((prevState) => ({...prevState, comment: event.target.value }))}/>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: "15px" }} colon={false}>
                    <Button htmlType="button" onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} onClick={() => onEditOrderElem()}>OK</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default OrdersEdit;