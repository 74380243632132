import React, { useState, useEffect } from 'react';

import { Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    CheckOutlined,
    ImportOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import ImportDictionaryControl from '@src/components/dictionaries/import-dictionary';

import { IDictionary } from '@entities/dictionary';
import { enumLabel } from '@src/core/enums/nstr-kind';

const Dictionaries = () => {
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<IDictionary>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [uploadDictionary, setUploadDictionary] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch('nstrs', { method: 'GET' })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения справочника', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Импорт',
                        key: 'import',
                        disabled: loading,
                        icon: <ImportOutlined />,
                        onClick: () => {
                            setUploadDictionary(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IDictionary> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 200,
            },
            {
                title: 'Компания',
                dataIndex: 'companyName',
                width: 200,
            },
            {
                title: 'Отдел',
                dataIndex: 'depName',
                width: 200,
            },
            {
                title: 'Тип',
                width: 150,
                render: (_, record) => {
                    return enumLabel(record.nstrKind);
                },
            },
            {
                title: 'sprId',
                dataIndex: 'sprId',
                width: 200,
            },
            {
                title: 'priceUnit',
                dataIndex: 'priceUnit',
                width: 200,
            },
            {
                title: 'art',
                dataIndex: 'art',
                width: 200,
            },
            {
                title: 'Измерение',
                dataIndex: 'dimension',
                width: 200,
            },
            {
                title: 'Коэфф. цены',
                dataIndex: 'coeffPrice',
                width: 150,
            },
            {
                title: 'Коэфф.отходов',
                dataIndex: 'coeffWaste',
                width: 200,
            },
            {
                title: 'dimensionUnit',
                dataIndex: 'dimensionUnit',
                width: 200,
            },
            {
                title: 'Удалено',
                align: 'center',
                width: 80,
                render: (_: any, record) => {
                    return record.isDeleted ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 200,
            },
            {
                title: 'Картинка',
                dataIndex: 'pictureId',
                width: 200,
            },
            {
                title: 'colorGroupId',
                dataIndex: 'colorGroupId',
                width: 200,
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: IDictionary) => {
                    return {
                        style: { cursor: 'pointer' },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {uploadDictionary && (
                <ImportDictionaryControl
                    open={uploadDictionary}
                    onCancel={() => {
                        setUploadDictionary(false);
                    }}
                    onLoadedFile={() => {
                        setUploadDictionary(false);
                        setRefreshRequired(true);
                    }}
                />)}

            {contextHolder}
        </>
    );
};

export default Dictionaries;
