export function GetLine(point1, point2) {
  if (!IsValidPoint(point1) || !IsValidPoint(point2) || IsSamePoints(point1, point2)) {
    return { a: NaN, b: NaN, c: NaN }; // Представление InvalidLine
  } else {
    return {
      a: point2.y - point1.y,
      b: point1.x - point2.x,
      c: point1.y * point2.x - point2.y * point1.x
    };
  }
}

export function GetIntersectionOfTwoLines(line1, line2) {
  if (!IsParallelLines(line1, line2)) {
    const x = (line2.b * line1.c - line1.b * line2.c) / (line2.a * line1.b - line1.a * line2.b);
    const y = (line1.a * line2.c - line2.a * line1.c) / (line2.a * line1.b - line1.a * line2.b);
    return { x, y };
  } else {
    return { x: NaN, y: NaN }; // Представление InvalidPoint
  }
}

export function GetDistanceBetweenPoints(point1, point2) {
  return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
}

export function GetPoint (X, Y) {
  if (!isNaN(X) && !isNaN(Y)) {
    return { x: X, y: Y };
  } else {
    return { x: NaN, y: NaN };
  }
}

export function GetVector (X, Y) {
  if (!isNaN(X) && !isNaN(Y)) {
    return { x: X, y: Y };
  } else {
    return { x: NaN, y: NaN }; 
  }
}

export function IsParallelLines(line1, line2) {
  return IsValidLine(line1) && IsValidLine(line2) && (line1.a * line2.b === line1.b * line2.a);
}

export function IsValidPoint (point) {
  return !isNaN(point.x) && !isNaN(point.y);
}

export function IsSamePoints(point1, point2) {
  return point1.x === point2.x && point1.y === point2.y;
}

export function IsValidLine(line) {
  return !isNaN(line.a) && !isNaN(line.b) && !isNaN(line.c);
}


export function GetAngleLines(x1m, y1m, x2m, y2m, x1x, y1x, x2x, y2x) {
  const k1 = GetVector(x2m - x1m, y2m - y1m);
  const k2 = GetVector(x2x - x1x, y2x - y1x);
  console.log(k1)
  console.log(x1x + " " + y1x + " " + x2x + " " + y2x)

  const alp = MIMatGetAlpVektors(k1, k2);
  console.log(alp)
  let result = 0;

  let angle = alp;
  if (angle < 0) angle += 360;
  if (angle >= 0 && angle <= 90) result = angle;
  if (angle > 90 && angle <= 180) result = 180 - angle;
  if (angle > 180 && angle <= 270) result = angle - 180;
  if (angle > 270 && angle <= 360) result = 360 - angle;

  return result;
}

export function MIMatRotateVektorGrd(v_1, angleInDegrees) {
  // Преобразуем угол в радианы
  const angleInRadians = angleInDegrees * Math.PI / 180;
  
  // Вычисляем новые координаты
  const xNew = v_1.x * Math.cos(angleInRadians) - v_1.y * Math.sin(angleInRadians);
  const yNew = v_1.x * Math.sin(angleInRadians) + v_1.y * Math.cos(angleInRadians);
  
  return { x: xNew, y: yNew };
}

// Получить угол между векторами против часовой стрелки в градусах
export function MIMatGetAlpVektors(v1, v2) {
    let cosa, sina, dx;
  
    // Проверка на нулевой вектор
    if (Math.sqrt(v1.x * v1.x + v1.y * v1.y) === 0 || Math.sqrt(v2.x * v2.x + v2.y * v2.y) === 0) {
      return 0;
    }

    // Нормируем векторы до ортов
    dx = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
    v1.x = v1.x / dx;
    v1.y = v1.y / dx;
    dx = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
    v2.x = v2.x / dx;
    v2.y = v2.y / dx;
    console.log(v2)
    sina = (v1.x * v2.y - v1.y * v2.x) / (v1.x * v1.x + v1.y * v1.y);
    cosa = (v1.x * v2.x + v1.y * v2.y) / (v1.x * v1.x + v1.y * v1.y);
  
    if (cosa > 1) cosa = 1;
    if (cosa < -1) cosa = -1;
    if (sina > 1) sina = 1;
    if (sina < -1) sina = -1;
  
    // Проверяем на граничные условия
    if (cosa === 1 && sina === 0) return 0;
    if (cosa === 0 && sina === 1) return 90;
    if (cosa === -1 && sina === 0) return 180;
    if (cosa === 0 && sina === -1) return 270;
  
    // Смотрим квадрант
    if (cosa > 0 && sina > 0) return Math.acos(cosa) * 180 / Math.PI; // 1-й квадрант
    if (cosa < 0 && sina > 0) return 90 + Math.acos(sina) * 180 / Math.PI; // 2-й квадрант
    if (cosa < 0 && sina < 0) return 180 + Math.acos(-cosa) * 180 / Math.PI; // 3-й квадрант
    if (cosa > 0 && sina < 0) return 270 + Math.acos(-sina) * 180 / Math.PI; // 4-й квадрант
  }

export function firstPointCalc(cxCalcX1, cxCalcY1, x_max_global, y_min_global, y_max_global, x_min_global, angle) {
    
    //Единичный вектор по OX
    const v_1 = MIMatRotateVektorGrd(GetVector(0, 1), angle);

    // Получаем первую точку
    const P0 = GetPoint(cxCalcX1, cxCalcY1);

    // Направляющая импоста
    const HighLine0 = GetLine(P0, GetPoint(P0.x + v_1.x, P0.y + v_1.y));

    // Вертикальная правая граница
    const HighLine1 = GetLine(GetPoint(x_max_global, y_min_global), GetPoint(x_max_global, y_max_global));

    // Горизонтальная верхняя граница
    const HighLine2 = GetLine(GetPoint(x_min_global, y_max_global), GetPoint(x_max_global, y_max_global));

    // Точки пересечения
    const P1 = GetIntersectionOfTwoLines(HighLine0, HighLine1);
    const P2 = GetIntersectionOfTwoLines(HighLine0, HighLine2);

    console.log(P1)
    console.log(P2)
    let PRes2 = null;

    if (IsValidPoint(P1) && IsValidPoint(P2)) {
    const L1 = GetDistanceBetweenPoints(P0, P1);
    const L2 = GetDistanceBetweenPoints(P0, P2);

    PRes2 = L1 < L2 ? P1 : P2;
    } else if (IsValidPoint(P1)) {
    PRes2 = P1;
    } else if (IsValidPoint(P2)) {
    PRes2 = P2;
    }

    return PRes2;
}

export function MiddlePointCalc(cxCalcX1, cxCalcX2, cxCalcY1, cxCalcY2, x_min_global, y_min_global, y_max_global, x_max_global, angle) {
  
  const v_1 = MIMatRotateVektorGrd(GetVector(1, 0), angle);
  // Получаем центральную точку
  const P0 = GetPoint((cxCalcX1 + cxCalcX2) / 2, (cxCalcY1 + cxCalcY2) / 2);

  // Направляющая импоста
  const HighLine0 = GetLine(P0, GetPoint(P0.x + v_1.x, P0.y + v_1.y));

  // Вертикальная левая граница
  let HighLine1 = GetLine(GetPoint(x_min_global, y_min_global), GetPoint(x_min_global, y_max_global));

  // Горизонтальная нижняя граница
  let HighLine2 = GetLine(GetPoint(x_min_global, y_min_global), GetPoint(x_max_global, y_min_global));

  // Точки пересечения
  let P1 = GetIntersectionOfTwoLines(HighLine0, HighLine1);
  let P2 = GetIntersectionOfTwoLines(HighLine0, HighLine2);

  let PRes1 = null;

  if (IsValidPoint(P1) && IsValidPoint(P2)) {
    const L1 = GetDistanceBetweenPoints(P0, P1);
    const L2 = GetDistanceBetweenPoints(P0, P2);

    PRes1 = L1 < L2 ? P1 : P2;
  } else if (IsValidPoint(P1)) {
    PRes1 = P1;
  } else if (IsValidPoint(P2)) {
    PRes1 = P2;
  }

  // Вертикальная правая граница
  HighLine1 = GetLine(GetPoint(x_max_global, y_min_global), GetPoint(x_max_global, y_max_global));

  // Горизонтальная верхняя граница
  HighLine2 = GetLine(GetPoint(x_min_global, y_max_global), GetPoint(x_max_global, y_max_global));

  // Точки пересечения
  P1 = GetIntersectionOfTwoLines(HighLine0, HighLine1);
  P2 = GetIntersectionOfTwoLines(HighLine0, HighLine2);

  let PRes2 = null;

  if (IsValidPoint(P1) && IsValidPoint(P2)) {
    const L1 = GetDistanceBetweenPoints(P0, P1);
    const L2 = GetDistanceBetweenPoints(P0, P2);

    PRes2 = L1 < L2 ? P1 : P2;
  } else if (IsValidPoint(P1)) {
    PRes2 = P1;
  } else if (IsValidPoint(P2)) {
    PRes2 = P2;
  }

  return { PRes1, PRes2 };
}

export function SecondPointCalc(cxCalcX2, cxCalcY2, x_max_global, y_min_global, y_max_global, x_min_global, angle) {
 
  const v_1 = MIMatRotateVektorGrd(GetVector(1, 0), angle);
  // Получаем первую точку
  const P0 = GetPoint(cxCalcX2, cxCalcY2);

  // Направляющая импоста
  const HighLine0 = GetLine(P0, GetPoint(P0.x + v_1.x, P0.y + v_1.y));
  console.log(HighLine0)
  // Вертикальная правая граница
  const HighLine1 = GetLine(GetPoint(x_min_global, y_min_global), GetPoint(x_min_global, y_max_global));

  // Горизонтальная верхняя граница
  const HighLine2 = GetLine(GetPoint(x_min_global, y_min_global), GetPoint(x_max_global, y_min_global));

  // Точки пересечения
  const P1 = GetIntersectionOfTwoLines(HighLine0, HighLine1);
  const P2 = GetIntersectionOfTwoLines(HighLine0, HighLine2);

  let PRes2 = null;
  let PRes1 = null;

  if (IsValidPoint(P1) && IsValidPoint(P2)) {
  const L1 = GetDistanceBetweenPoints(P0, P1);
  const L2 = GetDistanceBetweenPoints(P0, P2);

  PRes1 = L1 < L2 ? P1 : P2;
  // PRes2 = fixedObj(PRes2);
  // console.log('PRES2', PRes2)
  } else if (IsValidPoint(P1)) {
    console.log("P1 valid")
  PRes1 = P1;
  } else if (IsValidPoint(P2)) {
  PRes1 = P2;
  }

  return PRes1;
}

export function fixedObj (object) {
  if (object.x === -0) {
    object.x = 0;
  }
  return object
};


export function calculateDivisionPoints (straightOrient, height, width, leftMargin, rightMargin, step, divisionType, quantity) {
  let segments = [];
  let area = (straightOrient === 1 ? height : width);
  const total = area - leftMargin - rightMargin;
  
  let apprQuantity = total / step;
  console.log(apprQuantity);

  if (!Number.isInteger(apprQuantity)) {
      apprQuantity = parseInt(apprQuantity);
  }

  let offset = step * (quantity - 1);

  console.log(quantity)


  if (divisionType === 1) { // Равномерно
      const segmentWidth = total / (quantity + 1);
      for (let i = 1; i <= quantity; i++) {
          if (straightOrient === 1) {
              const y = (area-rightMargin) - i * segmentWidth;
              segments.push({
                  pointStart: { x: 0, y: y },
                  pointFinish: { x: width, y: y },
                  height: 0,
                  shape: {}
              })
          }
          else if (straightOrient === 2) {
              const x = leftMargin + i * segmentWidth;
              segments.push({
                  pointStart: { x: x, y: 0 },
                  pointFinish: { x: x, y: height },
                  height: 0,
                  shape: {}
              });
          }
      }
      return segments;
  } else {
      if (straightOrient === 1) {
          for (let i = 0; i < apprQuantity; i++) {
              let y = i * step;
              segments.push({
                              pointStart: { x: 0, y: y },
                              pointFinish: { x: width, y: y },
                              height: 0,
                              shape: {}
                          });
          }
      } else if (straightOrient === 2) {
          for (let i = 0; i < apprQuantity; i++) {
              let x = i * step;
              segments.push({
                              pointStart: { x: x, y: 0 },
                              pointFinish: { x: x, y: height },
                              height: 0,
                              shape: {}
                          });
          }
      }
  }
  
  if (divisionType === 2) {
      if (straightOrient === 1) {
          segments = segments.map(segment => {
              let newY = segment.pointStart.y + leftMargin;
      
              // Чистка краевых
              if (newY <= leftMargin || newY >= (area - rightMargin)) {
                  return null;
              }
      
              return {
                  pointStart: { x: segment.pointStart.x, y: newY },
                  pointFinish: { x: segment.pointFinish.x, y: newY },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment !== null); 
      } else if (straightOrient === 2) {
          segments = segments.map(segment => {
              let newX = segment.pointStart.x + leftMargin;
      
              // Чистка краевых
              if (newX <= leftMargin || newX >= (area - rightMargin)) {
                  return null;
              }
      
              return {
                  pointStart: { x: newX, y: segment.pointStart.y },
                  pointFinish: { x: newX, y: segment.pointFinish.y },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment !== null); 
      }
  } else if (divisionType === 3) { //По правому краю
      if (straightOrient === 1) {
          segments = segments.map(segment => {
              let newY = (area - rightMargin) - segment.pointStart.y;
              console.log(total - rightMargin)
              // Чистка краевых
              if (newY <= leftMargin || newY >= (area - rightMargin)) {
                  return null;
              }
      
              return {
                  pointStart: { x: segment.pointStart.x, y: newY },
                  pointFinish: { x: segment.pointFinish.x, y: newY },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment !== null); 
      } else if (straightOrient === 2) {
          segments = segments.map(segment => {
              let newX = (area - rightMargin) - segment.pointStart.x;
      
              // Чистка краевых
              if (newX <= leftMargin || newX >= (area - rightMargin)) {
                  return null;
              }
      
              return {
                  pointStart: { x: newX, y: segment.pointStart.y },
                  pointFinish: { x: newX, y: segment.pointFinish.y },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment !== null); 
      }
  } else if (divisionType === 4) {
      if (straightOrient === 1) {
          let diff = leftMargin + (total - offset)/2;
          // Корректируем координаты y для центрирования
          segments = segments.map(segment => {
          let centeredY = segment.pointStart.y + diff;
          
          // Чистка краевых
          if (centeredY<= leftMargin || centeredY >= (area - rightMargin)) {
              return null;
          }
              // Возвращаем обновленный сегмент
              return {
                  pointStart: { x: segment.pointStart.x, y: centeredY },
                  pointFinish: { x: segment.pointFinish.x, y: centeredY },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment != null);
      } else if (straightOrient === 2) {
          let diff = leftMargin + (total - offset)/2;
          // Корректируем координаты y для центрирования
          segments = segments.map(segment => {
          let centeredX = segment.pointStart.x + diff;
          
          // Чистка краевых
          if (centeredX<= leftMargin || centeredX >= (area - rightMargin)) {
              return null;
          }
              // Возвращаем обновленный сегмент
              return {
                  pointStart: { x: centeredX , y: segment.pointStart.y },
                  pointFinish: { x: centeredX, y: segment.pointFinish.y },
                  height: segment.height,
                  shape: segment.shape
              };
          }).filter(segment => segment != null);
      }
  }

  if (!((quantity > apprQuantity) || (quantity === 0))) {
      segments = segments.slice(0, quantity);
  }
  
  if (straightOrient === 1) {
      segments.sort((a, b) => b.pointStart.y - a.pointStart.y);
  } else if (straightOrient === 2) {
      segments.sort((a, b) => a.pointStart.x - b.pointStart.x);
  }

  return segments;
};