import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, DeleteFilled, PlusOutlined, QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';

import Toolbar, { ICommand } from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import CompanyModal from '@components/companies/company-modal';

import { ICompany } from '@entities/company';
import { IUserSession } from '@entities/user-session';

import './companies.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Companies = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<ICompany>>([]);
    const [currentEntity, setCurrentEntity] = useState<ICompany>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setCurrentEntity(undefined);
        setEntities([]);

        setLoading(true);

        serverFetch('companies', { method: 'GET' })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = (id: number) => {
        serverFetch(`companies/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления компании', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        let commands: Array<ICommand> = [
            {
                label: 'Обновить',
                key: 'refresh',
                disabled: loading,
                icon: <ReloadOutlined />,
                onClick: () => {
                    setRefreshRequired(true);
                },
            },
        ];

        if (userSession.isAdmin) {
            commands.push({
                label: 'Добавить',
                key: 'add',
                type: 'primary',
                icon: <PlusOutlined />,
                onClick: () => {
                    setCurrentEntity(undefined);
                    setOpenDialog(true);
                },
            });
        }

        return <Toolbar commands={commands} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 200,
            },
            {
                title: 'Активна',
                align: 'center',
                width: 80,
                render: (_: any, record) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 200,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                title: 'Юр. имя',
                dataIndex: 'legalName',
                width: 200,
            },
            {
                title: 'Юр. адрес',
                dataIndex: 'legalAddress',
                width: 200,
            },
            {
                title: 'Дата создания',
                dataIndex: 'createdOn',
                width: 120,
                render: (_, record) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        if (userSession.isAdmin) {
            columns.splice(0, 0, {
                align: 'center',
                width: 45,
                render: (_, record) => {
                    return (
                        <Button
                            className='delete-company-btn'
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();

                                Modal.confirm({
                                    title: `Удалить компанию "${record?.name}"?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                    );
                },
            });
        }

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: ICompany) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            <CompanyModal
                id={currentEntity?.id}
                openDialog={openDialog}
                onClose={(value: boolean) => {
                    setOpenDialog(false);
                    setRefreshRequired(value);
                }}
            />

            {contextHolder}
        </>
    );
};

export default Companies;
