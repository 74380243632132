import { exception } from "@extensions/notification";
import { cadServerFetch } from "@src/core/server";
import { Form, Input, Modal, Select, Tree, Typography, notification, Button, message, InputNumber, Row, Col, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getOptionsFromIdNameList as in2ops } from "./utils";
import './izd-create.css';

const { Option } = Select;

const IzdCreate = ({...props}) => {
  const { onCancel, onFinish, companyId, orderId } = props;
  const [api] = notification.useNotification();
  const [form] = Form.useForm();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zapolns, setZapolns] = useState([]);
  const [colors, setColors] = useState([]);
  const [monts, setMonts] = useState([]);
  const [vsts, setVsts] = useState([]);
  const [diffs, setDiffs] = useState([]);
  const [item, setItem] = useState();
  const [sideColors, setSideColors] = useState({});
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [defaultIds, setDefaultIds] = useState({ zapolnId: null, colorId: null });
  const [sysId, setSysId] = useState(null);
  const [paramData, setParamData] = useState([]);
  const [selectedParamCreate, setSelectedParamCreate] = useState({});
  const [allUserInputs, setAllUserInputs] = useState({})
  const [loadedKonstr, setLoadedKonstr] = useState(false);
  const [windowOptions, setWindowOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const { Title } = Typography;

  const paramColumns = [
    { title: 'Параметр', dataIndex: 'name', key: 'name', width: '60%' },
    { 
      title: 'Значение', 
      dataIndex: 'values', 
      key: 'values',
      render: (values, record) => {
        const selectedValue = selectedParamCreate?.find(param => param.key === record.id)?.value || record.defaultId;

        return (
          <Select
            value={selectedValue}
            style={{ width: '105%' }} 
            onChange={(value) => handleParamSelectChange(value, record.id)}
            disabled={record.isHidden}
          >
            {values.map(value => (
              <Option key={value.id} value={value.id}>{value.name}</Option>
            ))}
          </Select>
        );
      }
    }
  ];

  const handleParamSelectChange = (value, key) => {
    setSelectedParamCreate(prev => {
      const updatedValues = prev.filter(item => item.key !== key);
      updatedValues.push({ key, value });
      return updatedValues;
    });

    setAllUserInputs(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const newItem = { 
      ...item, 
      paramValues: selectedParamCreate
    };
    setItem(newItem);
    form.setFieldsValue(newItem);
  }, [selectedParamCreate])

  const getSideColors = (newColorId) => {
    cadServerFetch(`settings/color/SideColors?companyId=${companyId}&colorId=${newColorId || item.colorId}`)
      .then(data => setSideColors(data))
      .catch(e => console.log(e.message));
  };

  const initializeItem = () => {
    const storedHeight = localStorage.getItem('height');
    const storedWidth = localStorage.getItem('width');
    const storedQty = localStorage.getItem('qty');
    setSysId(localStorage.getItem('sysId'))

    const newItem = {
      height: storedHeight ? Number(storedHeight) : 1000,
      width: storedWidth ? Number(storedWidth) : 1000,
      qty: storedQty ? Number(storedQty) : 1,
      name: "",
      description: ""
    };

    setItem(newItem);
    form.setFieldsValue(newItem);
  };

  useEffect(() => {
    initializeItem();
    setLoading(true);
    cadServerFetch(`settings/systems/${companyId}`, { method: 'GET' })
      .then(data => {
        setProfiles(data);
      })
      .catch((ex) => {
        exception(api, 'Ошибка получения профильных систем', ex, () => {});
      });
      if (!loadedKonstr) {
        cadServerFetch(`Cad/Oper/Konstr?companyId=${companyId}`, { method: 'GET' })
          .then(data => {
            const options = data.map((item) => ({
              key: item.id,
              label: item.name,
              w: item.w,
              h: item.h,
              sysId: item.sysId,
              colorId: item.colorId,
              colorInId: item.colorInId,
              colorOutId: item.colorOutId,
              colorBaseId: item.colorBaseId,
              colorFId: item.colorFId,
              comment: item.comment,
              image: `${process.env.REACT_APP_API_BASE_URL}/api/cad/Oper/Konstr/Picture?companyId=${companyId}&konstrId=${item.id}`,
            }));
            setWindowOptions(options);
            console.log(data);
            if (windowOptions.length > 0) {
              setLoadedKonstr(true);}
          })
          .catch((ex) => {
            console.error('Ошибка получения профильных систем:', ex);
          });
      }
  }, []);

  const findPath = (tree, targetValue) => {
    const traverse = (node, path) => {
      if (parseInt(node.value) === parseInt(targetValue)) {
        return path;
      }
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          const result = traverse(node.children[i], [...path, i]);
          if (result) { return result; }
        }
      }
      return null;
    };
  
    for (let i = 0; i < tree.length; i++) {
      const result = traverse(tree[i], [i]);
      if (result) { return `0-${result.join('-')}`; }
    }
    return null;
  };

  useEffect(() => {
    if (sysId) {
      setLoading(true);
      cadServerFetch(`settings/systems/${companyId}/sprs/${sysId}`, { method: 'GET' })
        .then((data) => {
          console.log(data)
          setZapolns(data.zapolns);
          setColors(data.colors);
          setVsts(data.vsts);
          setMonts(data.monts);
          setDefaultIds({ zapolnId: data.zapolnId, colorId: data.colorId });
          setLoading(false);
        })
        .catch((ex) => {
          exception(api, 'Ошибка получения справочников', ex, () => {});
          setLoading(false);
        });
    }
  }, [sysId]);

  useEffect(() => {
    if (profiles.length > 0 && sysId) {
      let path = findPath(profiles, sysId);
      setSelectedKeys([path]);
      setExpandedKeys([path.slice(0, 3)]);
  
      const newItem = { 
        ...item, 
        sysId: Number(sysId),
        montId: monts.length > 0 ? monts[0].key : null,
        vstId: vsts.length > 0 ? vsts[0].key : null,
        width: localStorage.getItem('width') ? Number(localStorage.getItem('width')) : 1000,
        height: localStorage.getItem('height') ? Number(localStorage.getItem('height')) : 1000,
        colorId: selectedOption?.colorId ?? (defaultIds.colorId !== null && defaultIds.colorId !== undefined ? defaultIds.colorId : null),
        colorInId: selectedOption?.colorInId ?? null,
        colorOutId: selectedOption?.colorOutId ?? null,
        colorBaseId: selectedOption?.colorBaseId ?? null,
        colorFId: selectedOption?.colorFId ?? null,
        zapolnId: selectedOption ? null : defaultIds.zapolnId,
      };
      setItem(newItem);
      form.setFieldsValue(newItem);
    }
  }, [profiles, sysId, monts, vsts, defaultIds]);
  
  useEffect(() => {
    if (item?.sysId && item?.vstId) {
      let montage = 0;
      if (item?.montId) montage = item.montId;
      
      cadServerFetch(`settings/systems/params?companyId=${companyId}&sysId=${item.sysId}&vstId=${item.vstId}&montId=${montage}`)
        .then((data) => {
          setParamData(data);
          const initialValues = data.map(param => {

          const existingValue = allUserInputs[param.id];
            return {
              key: param.id,
              value: existingValue !== undefined ? existingValue : param.defaultId
            };
          });
          setSelectedParamCreate(initialValues);
        })
        .catch((ex) => {
          exception(api, 'Ошибка получения параметров', ex, () => {});
        });
    }
  }, [item?.sysId, item?.vstId, item?.montId]);

  const onValuesChange = (value) => {
    const newItem = { ...item, ...value };
    setItem(newItem);

    if (value.height !== undefined) localStorage.setItem('height', value.height);
    if (value.width !== undefined) localStorage.setItem('width', value.width);
    if (value.qty !== undefined) localStorage.setItem('qty', value.qty);
    if (value.colorId) getSideColors(value.colorId);
  };

  const CustomSelect = ({ windowOptions, selectedOption }) => {

    const handleChange = (value) => {
      const selected = windowOptions.find(option => option.key === value);
      setSelectedOption(selected);
      setSysId(selected.sysId);
      localStorage.setItem('sysId', selected.sysId); 
      localStorage.setItem('width', selected.w);
      localStorage.setItem('height', selected.h);
      const newItem = { 
        ...item,
        sysId: selected.sysId,
        zapolnId: null,
        width: selected.w,
        height: selected.h,
        colorId: selected.colorId,
        colorInId: selected.colorInId,
        colorOutId: selected.colorOutId,
        colorBaseId: selected.colorBaseId,
        colorFId: selected.colorFId,
      };
      form.setFieldsValue(newItem);
    };
  
    return (
      <div>
        <Select placeholder="Типовое" style={{ width: 450 }} value={selectedOption ? selectedOption.key : undefined} onChange={handleChange} optionLabelProp="label">
          {windowOptions.map(option => (
            <Select.Option key={option.key} value={option.key} label={option.label}>
              <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr 100px', gap: '10px', alignItems: 'center' }}>
                <img src={option.image} alt="window" style={{ width: '150px', height: 'auto' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{option.label}</div>
                  <div>{option.comment}</div>
                </div>
                <div style={{ textAlign: 'right' }}>{`${option.w} x ${option.h}`}</div>
              </div>
            </Select.Option>
          ))}
        </Select> </div>
    );
  };
    
  const onCreate = () => {
    setLoading(true);
    const kontrId = selectedOption && selectedOption.key ? selectedOption.key : null;
    const data = { ...item, companyId, orderId: Number(orderId), tipKontrId: kontrId,
      zapolnId: selectedOption ? (item.zapolnId ? item.zapolnId : 0) : item.zapolnId };
    console.log(data)
    cadServerFetch('cad/construction/new', { method: 'POST', bodyData: data })
      .then((data) => {
        onFinish(data);
      })
      .catch((ex) => {
        message.error(`Ошибка создания изделия ${ex.userMessage}`);
        setLoading(false);
      });
  };

  const getDisabled = (items) => !items || items.length === 0;

  const getCanSave = () => {
    if (
      !item ||
      !item.sysId ||
      (!selectedOption && !item.zapolnId) ||
      !item.colorId ||
      (!item.colorInId && sideColors && sideColors.colorsIn) ||
      (!item.colorOutId && sideColors && sideColors.colorsOut) ||
      (!item.colorBaseId && sideColors && sideColors.colorsBase) ||
      (!item.colorFId && sideColors && sideColors.colorsF) ||
      (vsts.length > 0 && !item.vstId) ||
      (monts.length > 0 && !item.montId) ||
      (diffs.length > 0 && !item.diffId)
    ) {
      return false;
    }
  
    const allParamsSelected = paramData.every(param => {
      const selectedParam = selectedParamCreate.find(p => p.key === param.id);
      return selectedParam && selectedParam.value !== undefined && selectedParam.value !== null;
    });
  
    return allParamsSelected;
  };

  const findSelectedItem = (treeData, indexes) => {
    let currentItem = treeData;
    for (let index of indexes) {
      if (Array.isArray(currentItem)) {
        currentItem = currentItem[index];
      } else if (currentItem.children) {
        currentItem = currentItem.children[index];
      } else {
        return null;
      }
    }
    return currentItem;
  };

  const onSelect = (selectedKeys) => {
    if (selectedKeys.length > 0) {
        setSelectedKeys(selectedKeys);
        const objString = JSON.stringify(selectedKeys);
        const keyArray = objString.replace(/[\[\]"]+/g, '').split('-').map(Number);
        keyArray.shift();
        const selectedProfile = findSelectedItem(profiles, keyArray);
        setSysId(selectedProfile.value);
        localStorage.setItem('sysId', selectedProfile.value); 
    }
  };

  const handleExpand = (expandedKeys) => { setExpandedKeys(expandedKeys); };

  return (
    <Modal open={true} onCancel={onCancel} forceRender closable={false} footer={null} getContainer={false} width={900}>
      <Row gutter={16}>
        <Col span={7}>
          <Form colon={false} form={form} size="medium">
            <Title style={{ fontSize: '16px', marginTop: 0 }}>Система</Title>
            <Form.Item>
              <Tree style={{ height: 400, overflow: 'auto' }} defaultExpandAll expandedKeys={expandedKeys}
                onSelect={onSelect} onExpand={handleExpand} selectedKeys={selectedKeys} treeData={profiles} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={17}>
          <Title style={{ fontSize: '16px', marginTop: 0 }}>Новое изделие</Title>
          <Form colon={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} disabled={loading} size="medium" onValuesChange={onValuesChange}>
            <Form.Item label="Типовое" name="typicalIzd" className="izd-create-item" colon={false}>
              <CustomSelect windowOptions={windowOptions} selectedOption={selectedOption}/>
            </Form.Item>
            <Form.Item label="Заполнение" name="zapolnId" className="izd-create-item">
              <Select options={zapolns} disabled={getDisabled(zapolns)} />
            </Form.Item>
            {item && (
              <Form.Item label="Цвет" name="colorId" className="izd-create-item0">
                <Row gutter={8}>
                  <Col span={12}><Select options={colors} disabled={getDisabled(colors)} onChange={(e) => onValuesChange({ colorId: e })} value={item.colorId} className="izd-create-item" /></Col>
                  {sideColors.colorsIn && (
                    <Col span={12}><Select placeholder="Изнутри" options={in2ops(sideColors.colorsIn)} value={item.colorInId} onChange={(e) => onValuesChange({ colorInId: e })} className="izd-create-item" /></Col>
                  )}
                  {sideColors.colorsOut && (
                    <Col span={12}><Select placeholder="Снаружи" options={in2ops(sideColors.colorsOut)} value={item.colorOutId} onChange={(e) => onValuesChange({ colorOutId: e })} className="izd-create-item" /></Col>
                  )}
                  {sideColors.colorsBase && (
                    <Col span={12}><Select placeholder="Основы" options={in2ops(sideColors.colorsBase)} value={item.colorBaseId} onChange={(e) => onValuesChange({ colorBaseId: e })} className="izd-create-item" /></Col>
                  )}
                  {sideColors.colorsF && (
                    <Col span={12}><Select placeholder="Фальца" options={in2ops(sideColors.colorsF)} value={item.colorFId} onChange={(e) => onValuesChange({ colorFId: e })} className="izd-create-item" /></Col>
                  )}
                </Row>
              </Form.Item>
            )}
            <Form.Item label="Вставка" name="vstId" className="izd-create-item" wrapperCol={{ span: 20 }}>
              <Row>
                <Col span={8}><Form.Item name="vstId" className="izd-create-item0"><Select options={vsts} disabled={getDisabled(vsts)} /></Form.Item></Col>
                <Col span={16}>
                  <Form.Item label="Монтаж" name="montId" className="izd-create-item0" labelCol={{ span: 9 }} wrapperCol={{ span: 13 }}><Select options={monts} disabled={getDisabled(monts)} /></Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Сложность" style={{ margin: 0 }} className="izd-create-item">
              <Row>
                <Col span={8}><Form.Item name="diffId" className="izd-create-item"><Select options={diffs} disabled={getDisabled(diffs)} /></Form.Item></Col>
                <Col span={16}><Form.Item label="Наименование" name="name" className="izd-create-item" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}><Input /></Form.Item></Col>
              </Row>
            </Form.Item>
            <Form.Item label="Комментарий" name="comment" className="izd-create-item"><Input /></Form.Item>
            <Form.Item label="Ширина" className="izd-create-item">
              <Row>
                <Col span={4}><Form.Item name="width"><InputNumber style={{ width: "100%" }} /></Form.Item></Col>
                <Col span={10}><Form.Item label="Высота" name="height" labelCol={{ span: 14 }} wrapperCol={{ span: 10 }}><InputNumber style={{ width: "100%" }} /></Form.Item></Col>
                <Col span={10}><Form.Item label="Количество" name="qty" labelCol={{ span: 14 }} wrapperCol={{ span: 10 }}><InputNumber style={{ width: "100%" }} /></Form.Item></Col>
              </Row>
            </Form.Item>
              <Table dataSource={paramData} size="small" columns={paramColumns} pagination={false} style={{ marginLeft: "125px", marginRight:"24px" }} />
            <Form.Item className="izd-create-item" wrapperCol={{ span: 18, offset: 5 }} style={{marginTop: '5px'}}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={onCancel} type="default">Отмена</Button>
                <Button onClick={onCreate} type={getCanSave() ? "primary" : "dashed"} disabled={!getCanSave()}>Создать</Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default IzdCreate;