import React from "react";

const ImgGetPropStv = ({...props}) => {
    const {disabled} = props;
    return<svg width="32" height="32">
        <g className={disabled ? "svg-cons-disabled" : "svg-cons"}>
            <rect rx="4" id="svg_2" height="28" width="28" y="2" x="2" fill="#fff"/>
            <line stroke-linecap="null" stroke-linejoin="null" y2="30" x2="16" y1="2" x1="16" fill="none"/>
        </g>
        <g className={disabled ? "svg-select-disabled" : "svg-select"}>
            <line stroke-linecap="null" stroke-linejoin="null" y2="23" x2="9" y1="16" x1="9" fill-opacity="null" fill="none" />
            <line stroke-linecap="null" stroke-linejoin="null" y2="14" x2="9" y1="12" x1="9" fill-opacity="null" fill="none" />
            <path d="M28 4 L16 16 L28 28" fill="none" stroke-linejoin="round"/>
        </g>
    </svg>    
}

export default ImgGetPropStv;