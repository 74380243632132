import { serverFetch } from "@src/core/server";
import { Table } from "antd";
import React, { useEffect, useState } from "react";

const CadSpec = ({...props}) => {
  const { ids, constructionId } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    serverFetch(`cad/oper/spec?constructionId=${constructionId}&sIds=${ids.join(',')}`)
      .then(data => {
        setLoading(false);
        setItems(data);
      })
      .catch(e => {
        console.log(e.userMessage);
        setLoading(false);
      })
  }

  useEffect(getData, [ids, constructionId]);

  const columns = [
    { dataIndex: 'matArt', title: 'Артикул' },
    { dataIndex: 'matName', title: 'Материал' },
    { dataIndex: 'color', title: 'Цвет' },
    { dataIndex: 'kol', title: 'Кол.', align: 'right' },
  ]

  return<Table dataSource={items} loading={loading} columns={columns} size="small" pagination={false} />
}

export default CadSpec