import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification, Modal, Tag } from 'antd';
import { RollbackOutlined, SaveOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { exception, success } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';
import Toolbar, { ICommand } from '@controls/toolbar/toolbar';
import { IClient } from '@entities/client';
import { IOrder, getEmptyOrder } from '@entities/index';
import { IOrderItem } from '@entities/order-item';
import { IUserSession } from '@entities/user-session';
import { NstrKind } from '@enums/nstr-kind';
import { UserLevel } from '@enums/user-level';
import './order.css';
import OrderItems from './order-items';
import OrderHead from './order-head';
import OrderAkses from './order-akses';
import OrderTotal from './order-total';
import Cad from '@src/cad/cad';
import { getSyncStatusColor, getSyncStatusName, SyncStatus } from '@src/core/enums/sync-status';
import { date2StringApi } from '@src/core/extensions/utils';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Order = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const navigate = useNavigate();
    const { id } = useParams();
    const [api, contextHolder] = notification.useNotification();
    const [modal, contextModalHolder] = Modal.useModal();
    const d = useAppDispatch();
    const [entity, setEntity] = useState<IOrder>(getEmptyOrder(new dayjs(new Date())));
    const [loading, setLoading] = useState<boolean>(false);
    const [entitiesProduct, setEntitiesProduct] = useState<Array<IOrderItem>>([]);
    const [entitiesAccessories, setEntitiesAccessories] = useState<Array<IOrderItem>>([]);
    const [loadingClients, setLoadingClients] = useState<boolean>(false);
    const [clients, setClients] = useState<Array<IClient>>([]);
    const [showCad, setShowCad] = useState<boolean>(false);
    const [cadId, setCadId] = useState<Number>(0);
    const disabled = !entity || (entity.syncStatus === SyncStatus.Ok ||entity.syncStatus === SyncStatus.Wait);

    const onGetData = () => {
        setLoading(true);
        serverFetch(`orders/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                if (data.items) {
                    setEntitiesProduct(data.items.filter((e: IOrderItem) => {
                        if (e.nstrKind === NstrKind.ProfileSystem)
                            return e;
                    }));
                    setEntitiesAccessories(data.items.filter((e: IOrderItem) => {
                        if (e.nstrKind === NstrKind.SetOfAccessories)
                            return e;
                    }));
                }
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения заказа', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!id) return;
        onGetData();
    }, [id]);

    useEffect(() => {
        setLoadingClients(true);

        serverFetch('clients', { method: 'GET', queryParams: { companyId: userSession.companyId, depId: userSession.depId } })
            .then((data) => {
                setClients(data);
                setLoadingClients(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения клиентов', ex, () => d(userLoaded(undefined)));
                setLoadingClients(false);
            });
    }, [userSession]);

    const validationOnSave = (data: IOrder) => {
        let result: string = '';
        if (!data.docDate) result += "Введите docDate";
        return result;
    };

    const renderToolbar = () => {
        let commands: Array<ICommand> = [
            {
                label: 'Назад',
                key: 'back',
                disabled: loading,
                icon: <RollbackOutlined />,
                onClick: () => {
                    navigate(-1);
                },
            },
        ];

        if (!disabled && (userSession.isAdmin || userSession.level == UserLevel.Dealer)) {
            commands.push({
                label: 'Сохранить',
                key: 'save',
                type: 'primary',
                icon: <SaveOutlined />,
                onClick: () => {
                    entity.companyId = userSession.companyId;
                    entity.depId = userSession.depId;
                    entity.userId = userSession.userId;
                    let validationMessage = validationOnSave(entity);
                    if (validationMessage) {
                        modal.warning({ title: 'Ошибка сохранения заказа', content: validationMessage });
                    } else {
                        serverFetch(`orders`, {
                            method: id ? 'PUT' : 'POST',
                            bodyData: {
                                ...entity,
                                docDate: date2StringApi(entity.docDate),
                                finishDate: date2StringApi(entity.finishDate),
                            },
                        })
                        .then((data) => {
                            success(api, 'Изменения успешно сохранены');
                            const orderId = data;
                            navigate(`/orders/${orderId}`, { replace: true });
                        })
                        .catch((ex) => exception(api, 'Ошибка сохранения заказа', ex, () => d(userLoaded(undefined))));
                    }
                },
            });

            if (!disabled) commands.push({
                label: 'Удалить',
                key: 'delete',
                icon: <DeleteOutlined />,
                disabled: !id,
                onClick: () => {
                    modal.confirm({
                        title: 'Удаление заказа',
                        content: `Вы действительно хотите удалить заказ ${entity.ordno}`,
                        icon: <QuestionCircleOutlined />,
                        onOk: () => {
                            serverFetch(`orders/${entity.id}`, { method: 'DELETE' })
                                .then(() => {
                                    navigate(-1);
                                })
                                .catch((ex) => {
                                    exception(api, 'Ошибка удаления заказа', ex, () => d(userLoaded(undefined)));
                                });
                        },
                    });
                },
            });
        }
        return <Toolbar title={`Заказ # ${id || ''}`} commands={commands} />;
    };

    const onCloseCad = () => {
        setShowCad(false);
        onGetData();
    }

    return (
        <>
            {renderToolbar()}
            {entity && <Tag color={getSyncStatusColor(entity.syncStatus)}>{getSyncStatusName(entity.syncStatus)}</Tag>}
            <OrderHead item={entity} loading={loading} loadingClients={loadingClients} setItem={setEntity} disabled={disabled}/>
            {id && entity && <OrderItems items={entitiesProduct} loading={loading} orderId={id} onRefresh={onGetData} disabled={disabled} onCad={(i:Number) => {
                setCadId(i);
                setShowCad(true);
            }} />}
            {false && id && entity && <OrderAkses loading={loading} items={entitiesAccessories} setEntitiesAccessories={setEntitiesAccessories}/>}
            {id && entity && <OrderTotal loading={loading} item={entity}/>}
            {contextHolder}
            {contextModalHolder}
            {showCad && !disabled && <Cad id={cadId} onClose={onCloseCad} />}
        </>
    );
};

export default Order;