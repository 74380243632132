import React from 'react';
import { Form, Input, Select } from 'antd';

const CadSplitThirdTab = ({ leftMargin, setLeftMargin, rightMargin, setRightMargin, quantity, setQuantity, step, setStep, divisionType, setDivisionType, gabOptions, multTypesOptions }) => {
    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}>
                <Form.Item label="Слева" name="margLeft" labelCol={{ span: 16 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={leftMargin} step="0.1" onChange={(e) => setLeftMargin(parseFloat(e.target.value))} />
                </Form.Item>
                <Form.Item label="Справа" name="margRight" labelCol={{ span: 10}} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={rightMargin} step="0.1" onChange={(e) => setRightMargin(parseFloat(e.target.value))} />
                </Form.Item>
                <Form.Item label="Количество" name="quantity" labelCol={{ span: 16 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
                </Form.Item>
                <Form.Item label="Шаг" name="step" labelCol={{ span: 10 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} disabled={divisionType === 1} step="0.1" value={step} onChange={(e) => setStep(parseFloat(e.target.value))} />
                </Form.Item>
            </div>
            <div>
                <Form.Item label="Габариты контура для вставки" name="CalculationType">
                    <Select options={gabOptions} />
                </Form.Item>
            </div>
            <div style={{ marginTop: 20 }}>
                <Form.Item label="Тип разделения" name="DivisionType" style={{ marginBottom: 16 }}>
                    <Select options={multTypesOptions} onChange={(value) => setDivisionType(value)} />
                </Form.Item>
            </div>
        </div>
    );
};

export default CadSplitThirdTab;