export enum UserLevel {
    Site = 0,
    Manufacturer = 1,
    Dealer = 2,
}

export const enumLabel = (value?: UserLevel): string => {
    switch (value) {
        case UserLevel.Site:
            return 'Сайт';
        case UserLevel.Manufacturer:
            return 'Производитель';
        case UserLevel.Dealer:
            return 'Дилер';
        default:
            return '';
    }
};
