import React, { useState, useEffect } from 'react';

import { Button, Modal, Input, InputNumber, Form, Space, Select, notification } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IDepartment } from '@entities/department';
import { ICompany } from '@entities/company';
import { IUserSession } from '@entities/user-session';
import { ICompanyFilter } from '@entities/company-filter';

import { UserLevel } from '@enums/user-level';

interface IDepartmentForm {
    id: number | undefined;
    openDialog: boolean;
    onClose: (value: boolean) => void;
}

const emptyEntity: IDepartment = {
    id: undefined,
    name: undefined,
    depno: null,
    priceCoeff: null,
    expCoeff: null,
    companyId: undefined,
};

const DepartmentModal = (props: IDepartmentForm) => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entity, setEntity] = useState<IDepartment>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<ICompany>>([]);

    useEffect(() => {
        if (!id || !openDialog) {
            return;
        }

        setLoading(true);

        serverFetch(`departments/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения отдела', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id, openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingCompanies(true);

        let filter: ICompanyFilter = {};
        if (userSession.level == UserLevel.Manufacturer) {
            filter.id = userSession.companyId;
            setEntity({ ...entity, companyId: userSession.companyId });
        }

        serverFetch('companies', { method: 'GET', queryParams: filter })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                setLoadingCompanies(false);
            });
    }, [openDialog]);

    const onFinishSave = () => {
        serverFetch(`departments`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                close(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения отдела', ex, () => d(userLoaded(undefined)));
            });
    };

    const close = (value: boolean) => {
        setEntity(emptyEntity);
        onClose(value);
    };

    return (
        <>
            {!loading && !loadingCompanies && (
                <Modal
                    title={`${id ? 'Изменить отдел' : 'Добавить отдел'}`}
                    destroyOnClose={true}
                    width={500}
                    open={openDialog}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form preserve={false} colon={false} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} onFinish={onFinishSave}>
                        <Form.Item initialValue={entity?.companyId} label='Компания' name='companyId'>
                            <Select
                                autoFocus
                                disabled={!userSession.isAdmin}
                                options={
                                    companies
                                        ? companies.map((e) => {
                                              return { value: e.id, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, companyId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.depno}
                            required
                            label='Номер'
                            name='depno'
                            rules={[{ required: true, message: 'Укажите номер отдела' }]}
                        >
                            <InputNumber
                                min={0}
                                disabled={!userSession.isAdmin}
                                onChange={(value) => {
                                    setEntity({ ...entity, depno: Number(value) });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.name}
                            required
                            label='Название'
                            name='name'
                            rules={[{ required: true, message: 'Укажите название отдела' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, name: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.priceCoeff}
                            required
                            label='Коэфф. цены поставки'
                            name='priceCoeff'
                            rules={[{ required: true, message: 'Укажите коэфф. цены поставки' }]}
                        >
                            <InputNumber
                                disabled={!userSession.isAdmin}
                                min={0}
                                onChange={(value) => {
                                    setEntity({ ...entity, priceCoeff: Number(value) });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.expCoeff}
                            required
                            label='Коефф. цены продажи'
                            name='expCoeff'
                            rules={[{ required: true, message: 'Укажите коефф. цены продажи' }]}
                        >
                            <InputNumber
                                disabled={!userSession.isAdmin}
                                min={0}
                                onChange={(value) => {
                                    setEntity({ ...entity, expCoeff: Number(value) });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='link' onClick={() => close(false)}>
                                    Отменить
                                </Button>
                                {userSession.isAdmin && (
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Сохранить
                                    </Button>
                                )}
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {contextHolder}
        </>
    );
};

export default DepartmentModal;
