import React from "react";
import { Circle } from "react-konva";
import useImage from "use-image";

const CadSoed = ({...props}) => {
  const { soed, visible, onSelect, onDblClick, onDragStart, onDragFinish, selections, scale } = props;
  const [image] = useImage('/images/stick.png');

  const fillData = selections.indexOf(soed.id) === -1 ? { fill: "blue", fillPatternScale: {x: 1, y: 1} } : { fillPatternImage: image, fillPatternScale: { x: 0.5/scale, y: 0.5/scale } }

  return visible ? <Circle {...soed.coord1} radius={20} {...fillData} stroke="black" strokeWidth={1} 
    onClick={(e) => { onSelect(e.target.attrs.objId, e.evt.shiftKey); }} onDblClick={(e) => onDblClick(e.target)} 
    id={`${soed.id}`} key={soed.id} objId={soed.id} draggable
    onDragStart={(e) => { onDragStart(e.target); }}
    onDragEnd={(e) => { onDragFinish(e.target); }}
  /> : null
}

export default CadSoed;