import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, DeleteFilled, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar, { ICommand } from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import DepartmentModal from '@components/departments/department-modal';

import { IDepartment } from '@entities/department';
import { IUserSession } from '@entities/user-session';
import { IDepartmentFilter } from '@entities/department-filter';

import { UserLevel } from '@enums/user-level';

import './departments.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Departments = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<IDepartment>>([]);
    const [currentEntity, setCurrentEntity] = useState<IDepartment>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setCurrentEntity(undefined);
        setEntities([]);

        setLoading(true);

        let filter: IDepartmentFilter = {};
        if (userSession.level == UserLevel.Manufacturer) {
            filter.companyId = userSession.companyId;
        }

        serverFetch('departments', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения отделов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = (id: number) => {
        serverFetch(`departments/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления отдела', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        let commands: Array<ICommand> = [
            {
                label: 'Обновить',
                key: 'refresh',
                disabled: loading,
                icon: <ReloadOutlined />,
                onClick: () => {
                    setRefreshRequired(true);
                },
            },
        ];

        if (userSession.isAdmin) {
            commands.push({
                label: 'Добавить',
                key: 'add',
                type: 'primary',
                icon: <PlusOutlined />,
                disabled: !userSession.isAdmin,
                onClick: () => {
                    setCurrentEntity(undefined);
                    setOpenDialog(true);
                },
            });
        }

        return <Toolbar commands={commands} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<IDepartment> = [
            {
                title: 'Компания',
                dataIndex: 'companyName',
                width: 150,
            },
            {
                title: 'Номер',
                dataIndex: 'depno',
                width: 150,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                width: 200,
            },
            {
                title: 'Коэфф. поставки',
                dataIndex: 'priceCoeff',
                width: 150,
            },
            {
                title: 'Коэфф. продажи',
                dataIndex: 'expCoeff',
                width: 150,
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        if (userSession.isAdmin) {
            columns.splice(0, 0, {
                align: 'center',
                width: 45,
                render: (_, record) => {
                    return (
                        <Button
                            className='delete-department-btn'
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();

                                Modal.confirm({
                                    title: `Удалить отдел "${record?.name}"?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                    );
                },
            });
        }

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record: IDepartment) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            <DepartmentModal
                id={currentEntity?.id}
                openDialog={openDialog}
                onClose={(value: boolean) => {
                    setOpenDialog(false);
                    setRefreshRequired(value);
                }}
            />

            {contextHolder}
        </>
    );
};

export default Departments;
