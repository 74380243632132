import { cadServerFetch } from "@src/core/server";
import { Checkbox, Input, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { ComSelectColor, ComSoedCoords } from "../components";

const CadOperPropsElem = ({...props}) => {
    const {item, companyId, sysId, setItem} = props;
    const [mats, setMats] = useState([]);

    const onSelectMat = (value, option) => {
        setItem({...item, materialId: value, materialName: option.name, materialArt: option.art});
        console.log(value, option);
    }

    useEffect(() => {
        cadServerFetch(`settings/sys/ElemMat/Mats?companyId=${companyId}&sysId=${sysId}`)
        .then(data => setMats(data))
        .catch(e => console.log(e.message))
    }, []);

    const matOptions = mats.map(i => ({value: i.id, label: `(${i.art}) ${i.name}`, name: i.name, art: i.art }));

    const divMain = <>
        <div className="oper-props-item">
            <div className="oper-props-item-title">Материал</div>
            <div className="oper-props-item-select">
                <Select style={{width: 416}} value={item.materialName} 
                    options={matOptions}  onSelect={onSelectMat} notFoundContent="Введите текст для поиска по имени или артикулу"/>
            </div>
        </div>
        <div className="oper-props-item" style={{height: "auto"}}>
            <div className="oper-props-item-title">Цвет</div>
            <div className="oper-props-item-select" style={{width: 200}}>
                <ComSelectColor {...item} companyId={companyId} sysId={sysId} setItemColors={(value) => setItem({...item, ...value})}/>
            </div>
        </div>
        <div className="oper-props-item">
            <div className="oper-props-item-title"><span className="oper-props-number">Соед 1</span><span>X</span></div>
            <div className="oper-props-item-select">
                <ComSoedCoords {...item.soed1} angle={item.alp1} sizes={[item.l1, item.m1, item.a1]} setItem={(coords) => setItem({...item, soed1: coords})}/>
            </div>
        </div>
        <div className="oper-props-item">
        <div className="oper-props-item-title"><span className="oper-props-number">Соед 2</span><span>X</span></div>
            <div className="oper-props-item-select">
                <ComSoedCoords {...item.soed2} angle={item.alp2} sizes={[item.l2, item.m2, item.a2]} setItem={(coords) => setItem({...item, soed2: coords})}/>
            </div>
        </div>
        <div className="oper-props-item">
            <div className="oper-props-item-title">Арка</div>
            <div className="oper-props-item-string">
                <Input value={item.h} style={{width: 72}} onChange={e => setItem({...item, h: Number(e.target.value)})} />
                <div>высота дуги</div>
            </div>
        </div>
        <div className="oper-props-item">
            <div className="oper-props-item-title"></div>
            <div className="oper-props-item-select">
                <Checkbox checked={item.sideInversion} onChange={e => setItem({...item, sideInversion: e.target.checked })}>Инверсия сторон для просчета соединений и прилеганий</Checkbox>
            </div>
        </div>
        <div className="oper-props-item">
            <div className="oper-props-item-title">Длина</div>
            <div className="oper-props-item-string">
                <Input value={item.w} style={{width: 72}} className="input-disabled"/>
                <div>Сторона</div><Input value={3} style={{width: 56}} className="input-disabled"/>
                <div>Угол наклона</div><Input value={item.alpGor} style={{width: 56}} className="input-disabled"/>
            </div>
        </div>
        <div className="oper-props-item">
            <div className="oper-props-item-title">Вариант</div>
            <div className="oper-props-item-select">
                <Input value={item.sprRshName} disabled style={{width: 420}}/>
                {/* <Select style={{width: 416}} /> */}
            </div>
        </div>
    </>

    console.log(item);

    return<div className="oper-props-body">
        {divMain}
    </div>
}

export default CadOperPropsElem;