import React, { useState, useEffect } from 'react';

import { Button, Modal, Input, InputNumber, Form, Space, notification, Select } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IOrderItem } from '@entities/order-item';
import { IDictionary } from '@entities/dictionary';
import { IUserSession } from '@entities/user-session';

import { NstrKind } from '@enums/nstr-kind';
import { UserLevel } from '@enums/user-level';
import { OrderParts } from '@enums/order-parts';

interface IAccessoryForm {
    data: IOrderItem | undefined;
    openDialog: boolean;
    onClose: (value: boolean, newEntity: IOrderItem | undefined) => void;
}

const emptyEntity: IOrderItem = {
    id: 0,
    name: undefined,
    orderId: null,
    parentId: null,

    syma1: 0,
    syma2: 0,
    syma3: 0,

    symaD1: 0,
    symaD2: 0,
    symaD3: 0,

    symaWD1: 0,
    symaWD2: 0,
    symaWD3: 0,

    w: 100,
    h: 100,
    qty: 1,

    nstrKind: NstrKind.SetOfAccessories,

    sprId: null,
    colorId: 0,
    colorInId: null,
    colorOutId: null,
    colorBaseId: null,
    colorFId: null,

    scheme: undefined,

    pictureId: null,

    posNumber: 0,
    comment: undefined,
    isCad: false,
    orderPart: OrderParts.Extra,
    level: UserLevel.Site,
    colorName: null,
    sysName: null
};

const AccessoryModal = (props: IAccessoryForm) => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const d = useAppDispatch();

    const { data, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IOrderItem>(emptyEntity);

    const [loadingAccessories, setLoadingAccessories] = useState<boolean>(false);
    const [accessories, setAccessories] = useState<Array<IDictionary>>([]);

    useEffect(() => {
        if (!data || !openDialog) {
            return;
        }

        setEntity(data);
    }, [data, openDialog]);

    const onFinishSave = () => {
        close(true);
    };

    const close = (value: boolean) => {
        let newEntity = entity;
        setEntity(emptyEntity);
        onClose(value, value ? newEntity : undefined);
    };

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingAccessories(true);

        serverFetch('nstrs', { method: 'GET', queryParams: { companyId: userSession.companyId, depId: userSession.depId, nstrKind: NstrKind.SetOfAccessories } })
            .then((data) => {
                setAccessories(data);
                setLoadingAccessories(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения валют', ex, () => d(userLoaded(undefined)));
                setLoadingAccessories(false);
            });
    }, [openDialog]);

    return (
        <>
            <Modal
                title={`${data ? 'Изменить аксессуар' : 'Добавить аксессуар'}`}
                destroyOnClose={true}
                width={500}
                open={openDialog}
                closable={false}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form preserve={false} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinishSave}>
                    <Form.Item
                        initialValue={entity.sprId}
                        required
                        label='Акссесуар'
                        name='sprId'
                        rules={[{ required: true, message: 'Выберите акссесуар' }]}
                    >
                        <Select
                            autoFocus
                            disabled={!userSession.isAdmin}
                            loading={loadingAccessories}
                            options={
                                accessories
                                    ? accessories.map((e) => {
                                        return { value: e.sprId, label: e.name };
                                    })
                                    : []
                            }
                            onChange={(value) => {
                                setEntity({ ...entity, sprId: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название' }]}
                    >
                        <Input
                            disabled={!userSession.isAdmin}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.syma1}
                        required
                        label='Сумма 1'
                        name='syma1'
                        rules={[{ required: true, message: 'Укажите сумму 1' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, syma1: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.syma2}
                        required
                        label='Сумма 2'
                        name='syma2'
                        rules={[{ required: true, message: 'Укажите сумму 2' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, syma2: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.syma3}
                        required
                        label='Сумма 3'
                        name='syma3'
                        rules={[{ required: true, message: 'Укажите сумму 3' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, syma3: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaD1}
                        required
                        label='Сумма Д 1'
                        name='symaD1'
                        rules={[{ required: true, message: 'Укажите сумму Д 1' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaD1: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaD2}
                        required
                        label='Сумма Д 2'
                        name='symaD2'
                        rules={[{ required: true, message: 'Укажите сумму Д 2' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaD2: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaD3}
                        required
                        label='Сумма Д 3'
                        name='symaD3'
                        rules={[{ required: true, message: 'Укажите сумму Д 3' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaD3: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaWD1}
                        required
                        label='Сумма ВД 1'
                        name='symaWD1'
                        rules={[{ required: true, message: 'Укажите сумму ВД 1' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaWD1: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaWD2}
                        required
                        label='Сумма ВД 2'
                        name='symaWD2'
                        rules={[{ required: true, message: 'Укажите сумму ВД 2' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaWD2: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.symaWD3}
                        required
                        label='Сумма ВД 3'
                        name='symaWD3'
                        rules={[{ required: true, message: 'Укажите сумму ВД 3' }]}
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, symaWD3: value ?? 0 });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.w}
                        label='Ширина'
                        name='w'
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, w: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.h}
                        label='Высота'
                        name='h'
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, h: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.qty}
                        label='Количество'
                        name='qty'
                    >
                        <InputNumber
                            disabled={!userSession.isAdmin}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, qty: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity.comment}
                        label='Комментарий'
                        name='comment'
                    >
                        <Input
                            disabled={!userSession.isAdmin}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, comment: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='link' onClick={() => close(false)}>
                                Отменить
                            </Button>
                            {userSession.isAdmin && (
                                <Button type='primary' htmlType='submit'>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </>
    );
};

export default AccessoryModal;
