export enum SyncStatus {
  None = 0,
  Ok = 1,
  Error = 2,
  Wait = 3,
}

export const getSyncStatusName = (status:SyncStatus) => {
  switch (status){
    case SyncStatus.Ok: return "OK";
    case SyncStatus.Error: return "Ошибка";
    case SyncStatus.Wait: return "Ожидание";
    default: return "Расчет";
  }
}

export const getSyncStatusColor = (status:SyncStatus) => {
  switch (status){
    case SyncStatus.Ok: return "green";
    case SyncStatus.Error: return "red";
    case SyncStatus.Wait: return "#ffdd2d";
    default: return "#898989";
  }
}