import React, { useEffect, useState } from "react";
import { Form, Select, Checkbox, Input, Button, Table, Modal, Row, Col, message } from "antd";
import { cadServerFetch } from "@src/core/server";
import './cad-sash.css';

const { Option } = Select;

const CadSash = ({ onCancel, constructionId, objId, companyId, onClose, setConstruction, mod }) => {
    const [invertContour, setInvertContour] = useState(false);
    const [systemData, setSystemData] = useState([]);
    const [sysId, setSysId] = useState(null);
    const [contData, setContData] = useState([]);
    const [contId, setContId] = useState();
    const [furnData, setFurnData] = useState([]);
    const [furnId, setFurnId] = useState();
    const [varId, setVarId] = useState();
    const [paramData, setParamData] = useState([]);
    const [isSystemDataLoaded, setIsSystemDataLoaded] = useState(false);
    const [foldParameters, setFoldParameters] = useState({ w: null, h: null });
    const [selectedParamValues, setSelectedParamValues] = useState();
    const [modObjId, setModObjId] = useState(objId);
    const [hasScrolled, setHasScrolled] = useState(false);

    const furnitureTableData = furnData.map(item => ({
        key: item.id,
        icon: <div dangerouslySetInnerHTML={{ __html: item.svg }} style={{ width: '70px', height: '70px' }} />,
        name: item.parentName,
        completion: item.name
    }));

    const columns = [
        { title: "Рис.", dataIndex: "icon", key: "icon", },
        { title: "Фурнитура", dataIndex: "name", key: "name", },
        { title: "Комплектация", dataIndex: "completion", key: "completion", },
    ];

    const paramColumns = [
        { title: 'Наименование', dataIndex: 'name', key: 'name'},
        { title: 'Значение', dataIndex: 'values', key: 'values',
            render: (values, record) => {
                const selectedValue = selectedParamValues?.find(param => param.key === record.id)?.value || record.defaultId;
    
                return (
                    <Select
                        value={selectedValue}
                        style={{ width: '100%' }} 
                        onChange={(value) => handleParamSelectChange(value, record.id)}
                        disabled={record.isHidden}
                    >
                        {values.map(value => (
                            <Option key={value.id} value={value.id}>{value.name}</Option>
                        ))}
                    </Select>
                );
            }
        }
    ];

    const onInsertSash = (withFurn) => {
        if (!companyId || !constructionId || !objId || !contId || !varId || !furnId) {
            message.error('Пожалуйста, заполните все необходимые поля перед продолжением.');
            return;
        }
        const sashBody = {
            companyId: parseInt(companyId),
            constructionId: constructionId,
            objectIds: [modObjId],
            consId: contId,
            varId: withFurn ? varId : 0,
            furnId: withFurn ? furnId : 0,
            paramValues: withFurn ? selectedParamValues : []
        }

        cadServerFetch(`cad/oper/sash/mod`, { method: 'POST', bodyData: sashBody})
                .then((data) => {
                    onClose(true);
                })
                .catch((ex) => {
                    message.error(`Ошибка вставки створки: ${ex.message}`);
        });
    };

    const handleParamSelectChange = (value, key) => {
        setSelectedParamValues(prev => {
            const updatedValues = prev.filter(item => item.key !== key);
            updatedValues.push({ key, value });
            return updatedValues;
        });
    };

    useEffect(() => {
        cadServerFetch(`Cad/Oper/Sash/Sys?constructionId=${constructionId}`)
            .then((data) => {
                if (!data) return;
                setSystemData(data.items);
                setSysId(data.defaultId);
                setIsSystemDataLoaded(true);
            })
            .catch(() => console.log("Ошибка получения базовой информации о системах"));
    }, [constructionId]);

    useEffect(() => {
        if (isSystemDataLoaded && sysId) {
            if (mod) { cadServerFetch(`Cad/Oper/Sash/Get?constructionId=${constructionId}&objId=${objId}`)
                .then((data) => {
                    if (!data) return;
                    if (data.consId != 0) {
                        setContId(data.consId);
                        setFurnId(data.furnId);
                        setVarId(data.varId);
                        const initialValues = data.paramValues.map(item => ({
                            key: item.key,
                            value: item.value
                        }));
                        setSelectedParamValues(initialValues);
                        setModObjId(parseInt(data.objectIds))
                    }
                    else {
                        message.error("Информация о створке отсутствует!");
                        return;
                    }
                })
                .catch(() => console.log("Ошибка получения базовой информации о контурах"));        
            }
            cadServerFetch(`Cad/Oper/Sash/Cons?constructionId=${constructionId}&objId=${objId}&sysId=${sysId}`)
                .then((data) => {
                    if (!data) return;
                    setContData(data.items);
                    if (!mod) setContId(data.defaultId);
                })
                .catch(() => console.log("Ошибка получения базовой информации о контурах"));
        }
    }, [sysId, isSystemDataLoaded]);

    useEffect(() => {
        if (isSystemDataLoaded && contId) {
            cadServerFetch(`Cad/Oper/Sash/Var?constructionId=${constructionId}&objId=${objId}&sysId=${sysId}&consId=${contId}`)
                .then((data) => {
                    if (!data) return;
                    setFoldParameters({ w: data.w, h: data.h });
                    setFurnData(data.items);
                    if (!mod) setVarId(data.defaultId);
                })
                .catch(() => console.log("Ошибка получения базовой информации о фурнитуре"));
        }
    }, [sysId, contId, isSystemDataLoaded]);

    useEffect(() => {
        if (isSystemDataLoaded && varId && hasScrolled) {
            let curFurn = furnData.find((elem) => elem.id == varId);
            setFurnId(curFurn.parentId);
        }
    }, [varId, isSystemDataLoaded]);

    useEffect(() => {
        if (isSystemDataLoaded && contId) {
            cadServerFetch(`Cad/Oper/Sash/Param?constructionId=${constructionId}&objId=${objId}&sysId=${sysId}&consId=${contId}`)
                .then((data) => {
                    if (!data) return;
                    if (!mod) {
                        const initialValues = data.map(item => ({
                            key: item.id,
                            value: item.defaultId
                        }));
                        setSelectedParamValues(initialValues);
                    }   
                    setParamData(data);
                })
                .catch(() => console.log("Ошибка получения базовой информации о параметрах"));
        }
    }, [sysId, contId, isSystemDataLoaded]);

    useEffect(() => {
        if (isSystemDataLoaded && furnData.length > 0 && !hasScrolled) {
            const tableBody = document.querySelector('.furniture-table .ant-table-body');
            if (tableBody) {
                const selectedElement = furnData.find(item => item.id === varId);
                if (selectedElement) {
                    const row = tableBody.querySelector(`[data-row-key="${varId}"]`);
                    if (row) {
                        row.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                        setHasScrolled(true); 
                    }
                }
            }
        }
    }, [isSystemDataLoaded, furnData, varId, hasScrolled]);

    const onSelectSystem = (value, option) => { setSysId(value); }
    const onSelectCont = (value, option) => { setContId(value); }

    const handleRowClick = (record) => {
        setVarId(record.key);
    };

    const rowClassName = (record) => {
        return record.key === varId ? 'selected-row' : '';
    };

    if (!sysId || !contId || (!mod && (!foldParameters.w || !foldParameters.h))) {
        return null;
    }

    const systemOptions = systemData.map(i => ({ value: i.id, label: i.name }));
    const contOptions = contData.map(i => ({ value: i.id, label: i.name }));

    return (
        <Modal open={true} onCancel={onCancel} forceRender closable={false} footer={null} getContainer={false} width={1000} title="Вставка створки">
            <Form initialValues={{ hFold: foldParameters.h, wFold: foldParameters.w, system: sysId, contourVariant: contId }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item style={{ marginLeft: '54px' }} label="Система" name="system" colon={false}>
                            <Select options={systemOptions} value={sysId} onSelect={onSelectSystem} />
                        </Form.Item>

                        <Form.Item label="Вариант контура" name="contourVariant" colon={false}>
                            <Select options={contOptions} value={contId} onSelect={onSelectCont} />
                        </Form.Item>

                        <Form.Item name="invertContour" valuePropName="checked" colon={false}>
                            <Checkbox disabled={true} checked={invertContour} onChange={(e) => setInvertContour(e.target.checked)}>
                                Инвертировать родительский контур
                            </Checkbox>
                        </Form.Item>

                        <Table dataSource={furnitureTableData} columns={columns} pagination={false} scroll={{ y: 400 }} className="furniture-table"
                            onRow={(record) => ({ onClick: () => handleRowClick(record), })} rowClassName={rowClassName} />
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Высота створки по фальцу, мм" name="hFold" colon={false} style={{ marginLeft: '28px' }}>
                            <Input type="number" disabled={true} />
                        </Form.Item>

                        <Form.Item label="Ширина створки по фальцу, мм" name="wFold" colon={false} style={{ marginLeft: '20px' }}>
                            <Input type="number" disabled={true} />
                        </Form.Item>

                        <Table dataSource={paramData} columns={paramColumns} pagination={false} className="parameter-table" scroll={{ y: 462 }} />
                    </Col>
                </Row>
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: "15px" }} colon={false}>
                    <Button htmlType="button" onClick={onCancel}>Cancel</Button>
                    <Button htmlType="button" style={{ marginLeft: "10px" }} onClick={() => onInsertSash(false)}>Без фурнитуры</Button>
                    <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} onClick={() => onInsertSash(true)}>OK</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CadSash;