export enum NstrKind {
    Material = 1,
    Color = 2,
    ProfileSystem = 3,
    SetOfAccessories = 4,
    ColorGroups = 5,
    Currency = 6,
    GroupOfMaterials = 7,
    PriceGroup = 8,
    PaymentType = 101,
    OrderStatus = 102,
}

export const enumLabel = (value?: NstrKind): string => {
    switch (value) {
        case NstrKind.Material:
            return 'Материал';
        case NstrKind.Color:
            return 'Цвет';
        case NstrKind.ProfileSystem:
            return 'Профильная система';
        case NstrKind.SetOfAccessories:
            return 'Комплект аксессуаров';
        case NstrKind.ColorGroups:
            return 'Цветовые группы';
        case NstrKind.Currency:
            return 'Валюта';
        case NstrKind.GroupOfMaterials:
            return 'Группа материалов';
        case NstrKind.PriceGroup:
            return 'Ценовая группа';
        case NstrKind.PaymentType:
            return 'Тип оплаты';
        case NstrKind.OrderStatus:
            return 'Статус заказа';
        default:
            return '';
    }
};
