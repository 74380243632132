import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Space, notification} from 'antd';
import { useParams } from 'react-router-dom';
import { exception, success } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';
import { ICompany } from '@entities/company';
import { IUserSession } from '@entities/user-session';

const emptyEntity: ICompany = {
    id: undefined,
    name: undefined,
    email: undefined,
    phone: undefined,
    isActive: false,
    legalName: undefined,
    legalAddress: undefined,
};

const Company = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams();
    const d = useAppDispatch();
    const [entity, setEntity] = useState<ICompany>(emptyEntity);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        serverFetch(`companies/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                form.setFieldsValue(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компании', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id]);

    const onFinishSave = () => {
        serverFetch(`companies`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => success(api, 'Изменения успешно сохранены'))
            .catch((ex) => exception(api, 'Ошибка сохранения страны', ex, () => d(userLoaded(undefined))));
    };

    const onValuesChange = (value : any) => setEntity({...entity, ...value});

    return (
        <>
            {!loading && (
                <Form preserve={false} colon={false} labelCol={{ span: 2 }} wrapperCol={{ span: 8 }} onFinish={onFinishSave} onValuesChange={onValuesChange} form={form}>
                    <Form.Item label="#" name="id"><Input disabled={true} /></Form.Item>
                    <Form.Item required label='Название' name='name' rules={[{ required: true, message: 'Укажите название компании' }]}>
                        <Input disabled={!userSession.isAdmin} autoFocus/>
                    </Form.Item>
                    <Form.Item label='Email' name='email'>
                        <Input disabled={!userSession.isAdmin} />
                    </Form.Item>
                    <Form.Item required label='Телефон' name='phone' rules={[{ required: true, message: 'Укажите телефон' }]}>
                        <Input disabled={!userSession.isAdmin} />
                    </Form.Item>
                    <Form.Item required label='Юр. имя' name='legalName' rules={[{ required: true, message: 'Укажите юр. имя' }]} >
                        <Input disabled={!userSession.isAdmin} />
                    </Form.Item>
                    <Form.Item required label='Юр. адрес' name='legalAddress' rules={[{ required: true, message: 'Укажите юр. адрес' }]} >
                        <Input disabled={!userSession.isAdmin} />
                    </Form.Item>
                    {userSession.isAdmin && <Form.Item label='Токен' name='password'><Input /></Form.Item>}
                    <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            {userSession.isAdmin && (
                                <Button type='primary' htmlType='submit' loading={loading}> Сохранить </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Company;
