import React, { useState, useEffect } from 'react';

import { Button, Modal, Input, Form, Space, notification, Switch } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ICompany } from '@entities/company';
import { IUserSession } from '@entities/user-session';

interface ICompanyForm {
    id: number | undefined;
    openDialog: boolean;
    onClose: (value: boolean) => void;
}

const emptyEntity: ICompany = {
    id: undefined,
    name: undefined,
    email: undefined,
    phone: undefined,
    isActive: false,
    legalName: undefined,
    legalAddress: undefined,
};

const Company = (props: ICompanyForm) => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entity, setEntity] = useState<ICompany>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!id || !openDialog) {
            return;
        }

        setLoading(true);

        serverFetch(`companies/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компании', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id, openDialog]);

    const onFinishSave = () => {
        serverFetch(`companies`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                close(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения страны', ex, () => d(userLoaded(undefined)));
            });
    };

    const close = (value: boolean) => {
        setEntity(emptyEntity);
        onClose(value);
    };

    return (
        <>
            {!loading && (
                <Modal
                    title={`${id ? 'Изменить компанию' : 'Добавить компанию'}`}
                    destroyOnClose={true}
                    width={500}
                    open={openDialog}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form preserve={false} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinishSave}>
                        <Form.Item
                            initialValue={entity?.name}
                            required
                            label='Название'
                            name='name'
                            rules={[{ required: true, message: 'Укажите название компании' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                autoFocus
                                onChange={(data) => {
                                    setEntity({ ...entity, name: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.email}
                            required
                            label='Email'
                            name='email'
                            rules={[{ required: true, message: 'Укажите email' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, email: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.phone}
                            required
                            label='Телефон'
                            name='phone'
                            rules={[{ required: true, message: 'Укажите телефон' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, phone: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.legalName}
                            required
                            label='Юр. имя'
                            name='legalName'
                            rules={[{ required: true, message: 'Укажите юр. имя' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, legalName: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.legalAddress}
                            required
                            label='Юр. адрес'
                            name='legalAddress'
                            rules={[{ required: true, message: 'Укажите юр. адрес' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, legalAddress: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item label='Активный'>
                            <Switch
                                disabled={!userSession.isAdmin}
                                defaultChecked={entity?.isActive}
                                onChange={(value) => {
                                    setEntity({ ...entity, isActive: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='link' onClick={() => close(false)}>
                                    Отменить
                                </Button>
                                {userSession.isAdmin && (
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Сохранить
                                    </Button>
                                )}
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {contextHolder}
        </>
    );
};

export default Company;
