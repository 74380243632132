import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteFilled,
    PlusOutlined,
    QuestionCircleOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import CurrencyRate from '@components/currency-rates/currency-rate';

import { ICurrencyRate } from '@entities/currency-rate';

import './currency-rates.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const CurrencyRates = () => {
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<ICurrencyRate>>([]);
    const [currentEntity, setCurrentEntity] = useState<ICurrencyRate>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setCurrentEntity(undefined);
        setEntities([]);

        setLoading(true);

        serverFetch('currencyrates', { method: 'GET' })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения курса валют', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = (id: number) => {
        serverFetch(`currencyrates/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления курса валют', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            setCurrentEntity(undefined);
                            setOpenDialog(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ICurrencyRate> = [
            {
                align: 'center',
                width: 45,
                render: (_, record) => {
                    return (
                        <Button
                            className='delete-currency-rate-btn'
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();

                                Modal.confirm({
                                    title: `Удалить курс валют?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                    );
                },
            },
            {
                title: 'Компания',
                dataIndex: 'companyName',
                width: 200,
            },
            {
                title: 'Дата',
                width: 120,
                render: (_, record) => {
                    return record.date && dayjs.utc(record.date).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Валюта',
                dataIndex: 'currencyName',
                width: 150,
            },
            {
                title: 'Ставка',
                dataIndex: 'rate',
                width: 200,
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: ICurrencyRate) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            <CurrencyRate
                id={currentEntity?.id}
                openDialog={openDialog}
                onClose={(value: boolean) => {
                    setOpenDialog(false);
                    setRefreshRequired(value);
                }}
            />

            {contextHolder}
        </>
    );
};

export default CurrencyRates;
