import React from "react";

const ImgGetMosk = ({...props}) => {
    const {disabled} = props;
    return <svg width="32" height="32">
      <rect className={disabled ? "svg-cons-disabled" : "svg-cons" } rx="4" height="28" width="28" y="2" x="2" fill="#fff"/>
      <g className={disabled ? "svg-select-disabled" : "svg-select"}>
        <line stroke-linecap="null" stroke-linejoin="null" y2="26" x2="12" y1="6" x1="12" fill="none"/>
        <line stroke-linecap="null" stroke-linejoin="null" y2="26" x2="20" y1="6" x1="20" fill="none"/>
        <line stroke-linecap="null" stroke-linejoin="null" y2="12" x2="26" y1="12" x1="6" fill="none"/>
        <line stroke-linecap="null" stroke-linejoin="null" y2="20" x2="26" y1="20" x1="6" fill="none"/>
      </g>
    </svg>    
}

export default ImgGetMosk;