import { serverFetch } from "@src/core/server";
import { Table } from "antd";
import React, { useEffect, useState } from "react";

const CadBasaRsh = ({...props}) => {
  const { objectId, constructionId } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    serverFetch(`cad/oper/ObjBasaRsh?constructionId=${constructionId}&objId=${objectId}`)
      .then(data => {
        setItems(data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e.userMessage);
        setLoading(false);
      })
  }

  useEffect(getData, [constructionId, objectId]);

  const columns = [
    { dataIndex: 'key', title: 'Наименование' },
    { dataIndex: 'value', title: 'Значение', align: 'center' },
  ]

  return<Table dataSource={items} loading={loading} columns={columns} size="small" pagination={false} />
}

export default CadBasaRsh;