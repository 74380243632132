import { Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ComSelectZapolnElem } from "../components";
import ComSelectZapoln from "../components/com-select-zapoln-mat";
import { getSizeText } from "../utils";

const CadOperPropsZapoln = ({...props}) => {
    const {item, companyId, sysId, setItem} = props;
    const [cnts, setCnts] = useState(item.cnts);

    useEffect(() => {
    }, []); 

    const handleDelete = (index) => {
        const newCnts = cnts.filter((_, i) => i !== index);
        setCnts(newCnts);
        setItem({ ...item, cnts: newCnts });
    };

    const handleMoveUp = (index) => {
        if (index > 0) {
            const newCnts = [...cnts];
            [newCnts[index - 1], newCnts[index]] = [newCnts[index], newCnts[index - 1]];
            setCnts(newCnts);
            setItem({ ...item, cnts: newCnts });
        }
    };

    const handleMoveDown = (index) => {
        if (index < cnts.length - 1) {
            const newCnts = [...cnts];
            [newCnts[index], newCnts[index + 1]] = [newCnts[index + 1], newCnts[index]];
            setCnts(newCnts);
            setItem({ ...item, cnts: newCnts });
        }
    };

    const handleAdd = () => {
        const newElem = {
            mat: { id: null, name: "Новый материал", art: "" },
            color: { id: null, name: "Новый цвет", vid: 0 },
            colorIn: null,
            colorOut: null,
            colorBase: null,
            colorF: null,
            kind: 1,
            thickness: 0,
            tags: null,
            vid: 0,
            id: cnts.length,
            name: "Новый элемент"
        };
        
        const newCnts = [...cnts, newElem];
        setCnts(newCnts);
        setItem({ ...item, cnts: newCnts });
    };
    
    const handleSelectZapoln = (selectedCnts) => {
        setCnts(selectedCnts);
        setItem({ ...item, cnts: selectedCnts });
    };

    const divElems = cnts.map((elem, index) => <ComSelectZapolnElem elem={elem} index={index} len={cnts.length}
    companyId={companyId}
    sysId={sysId}
    className="input-disabled"
    onDelete={() => handleDelete(index)}
    onMoveUp={() => handleMoveUp(index)}
    onMoveDown={() => handleMoveDown(index)}
    onChange={(changedElem) => {
        const newCnts = [...cnts];
        newCnts[index] = changedElem;
        setCnts(newCnts);
        setItem({ ...item, cnts: newCnts });
    }}/>)

    return (
            <div className="oper-props-body">
                <div className="oper-props-item">
                    <div className="oper-props-item-title">Типовое</div>
                    <div className="oper-props-item-select">
                        <ComSelectZapoln
                            companyId={companyId}
                            sysId={sysId}
                            onSelectZapoln={handleSelectZapoln} />
                    </div>
                </div>
                <div className="oper-props-item">
                    <div className="oper-props-item-title">Габариты</div>
                    <div className="oper-props-item-select">
                        <Input value={getSizeText(item.width) + ' x ' + getSizeText(item.height) + ' x ' + getSizeText(item.thickness)} className="input-disabled" style={{ width: 200 }} />
                    </div>
                </div>
                {divElems}
                <Button type="primary" style={{ width: 200, marginLeft: 112, marginTop: 8 }} onClick={handleAdd}> Добавить </Button>
            </div>
    )
}

export default CadOperPropsZapoln;