import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { GetAngleLines, MiddlePointCalc, firstPointCalc, SecondPointCalc } from "./cad-split-calc";

const pointToCalc = new Map([
    [1, "Первая точка"],
    [2, "Середина"],
    [3, "Вторая точка"]
]);

const CadSplitSecondTab = ({ form, width, height, points, setPoints, angle, setAngle, pointToCalcValue, setPointToCalcValue }) => {
    const [isCalculating, setIsCalculating] = useState(false);

    const handlePointsChange = (changedPoints) => {
        const validatedPoints = {};
        for (const [key, value] of Object.entries(changedPoints)) {
            validatedPoints[key] = isNaN(value) ? points[key] : value;
        }
        setPoints((prevPoints) => ({ ...prevPoints, ...validatedPoints }));
        const newAngle = GetAngleLines(
            0, 0, 1, 0,
            validatedPoints.Point1X, validatedPoints.Point1Y,
            validatedPoints.Point2X, validatedPoints.Point2Y
        );
        setAngle(newAngle);
        setIsCalculating(true);
    };

    useEffect(() => {
        if (isCalculating === true) {
            form.setFieldValue("Point1X", points.Point1X.toFixed(1));
            form.setFieldValue("Point2X", points.Point2X.toFixed(1));
            form.setFieldValue("Point1Y", points.Point1Y.toFixed(1));
            form.setFieldValue("Point2Y", points.Point2Y.toFixed(1));
            console.log('Изменен angle:', angle)
            form.setFieldValue("AngleRotate", angle.toFixed(1));
            setIsCalculating(false);
        }
    }, [isCalculating]);

    const recalculatePoints = () => {
        if (pointToCalcValue === 1) {
            let pt2 = firstPointCalc(points.Point1X, points.Point1Y, width, 0, height, 0, angle);
            if (isNaN(pt2.x) || isNaN(pt2.y)) return;
            setPoints(prevPoints => ({
                ...prevPoints,
                Point2X: parseFloat(pt2.x.toFixed(1)),
                Point2Y: parseFloat(pt2.y.toFixed(1))
            }));
        } else if (pointToCalcValue === 2) {
            let newPts = MiddlePointCalc(points.Point1X, points.Point2X, points.Point1Y, points.Point2Y,
                0, 0, height, width, angle);
            if (isNaN(newPts.PRes1.x) || isNaN(newPts.PRes1.y) || isNaN(newPts.PRes2.x) || isNaN(newPts.PRes2.y)) return;
            setPoints(prevPoints => ({
                ...prevPoints,
                Point1X: parseFloat(newPts.PRes1.x.toFixed(1)),
                Point1Y: parseFloat(newPts.PRes1.y.toFixed(1)),
                Point2X: parseFloat(newPts.PRes2.x.toFixed(1)),
                Point2Y: parseFloat(newPts.PRes2.y.toFixed(1))
            }));
        } else if (pointToCalcValue === 3) {
            let pt1 = SecondPointCalc(points.Point2X, points.Point2Y, width, 0, height, 0, angle);
            if (isNaN(pt1.x) || isNaN(pt1.y)) return;
            setPoints(prevPoints => ({
                ...prevPoints,
                Point1X: pt1.x,
                Point1Y: pt1.y
            }));
        }
    };

    const handleAngleChange = (newAngle) => {
        if (isNaN(newAngle)) return;
        setAngle(newAngle);
        recalculatePoints();
        setIsCalculating(true);
    };

    const handlePointToCalcChange = (value) => {
        if (isNaN(value)) return;
        setPointToCalcValue(value);
        recalculatePoints();
        setIsCalculating(true);
    };

    const pointVars = Array.from(pointToCalc.entries()).map(([value, label]) => ({ value, label }));

    useEffect(() => {
        if (isCalculating === true) {
            setIsCalculating(false);
        }
    }, [isCalculating]);

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}>
                <Form.Item label="Точка 1 X" name="Point1X"  labelCol={{ span: 16 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={points.Point1X} step="0.1" onChange={(e) => handlePointsChange({ Point1X: parseFloat(e.target.value) })}/>
                </Form.Item>
                <Form.Item label="Точка 2 X" name="Point2X"  labelCol={{ span: 10 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={points.Point2X} step="0.1" onChange={(e) => handlePointsChange({ Point2X: parseFloat(e.target.value) })}/>
                </Form.Item>
                <Form.Item label="Точка 1 Y" name="Point1Y"  labelCol={{ span: 16 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={points.Point1Y} step="0.1" onChange={(e) => handlePointsChange({ Point1Y: parseFloat(e.target.value) })}/>
                </Form.Item>
                <Form.Item label="Точка 2 Y" name="Point2Y"  labelCol={{ span: 10 }} wrapperCol={{ span: 32 }}>
                    <Input style={{ width: 109 }} value={points.Point2Y} step="0.1" onChange={(e) => handlePointsChange({ Point2Y: parseFloat(e.target.value) })}/>
                </Form.Item>
            </div>
            <Form.Item label="Угол наклона к горизонтали" name="AngleRotate">
                <Input style={{ width: 109 }} value={angle} step="0.1" onChange={(e) => handleAngleChange(parseFloat(e.target.value))}/>
            </Form.Item>
            <Form.Item label="Точка" name="PointToCalc">
                <Select options={pointVars} onChange={handlePointToCalcChange} />
            </Form.Item>
        </div>
    );
};

export default CadSplitSecondTab;