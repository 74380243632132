import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, DeleteFilled, PlusOutlined, QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';

import Toolbar, { ICommand } from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import User from '@components/users/user';

import { IUser } from '@entities/user';
import { IUserFilter } from '@entities/user-filter';
import { IUserSession } from '@entities/user-session';

import { UserLevel, enumLabel } from '@enums/user-level';

import './users.css';

const Companies = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<IUser>>([]);
    const [currentEntity, setCurrentEntity] = useState<IUser>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setCurrentEntity(undefined);
        setEntities([]);

        setLoading(true);

        let filter: IUserFilter = {};
        if (userSession.level >= UserLevel.Manufacturer) {
            filter.companyId = userSession.companyId;

            if (userSession.level == UserLevel.Dealer) {
                filter.depId = userSession.depId;
            }
        }

        serverFetch('users', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = (id: number) => {
        serverFetch(`users/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        let commands: Array<ICommand> = [
            {
                label: 'Обновить',
                key: 'refresh',
                disabled: loading,
                icon: <ReloadOutlined />,
                onClick: () => {
                    setRefreshRequired(true);
                },
            },
        ];

        if (userSession.isAdmin) {
            commands.push({
                label: 'Добавить',
                key: 'add',
                type: 'primary',
                icon: <PlusOutlined />,
                disabled: !userSession.isAdmin,
                onClick: () => {
                    setCurrentEntity(undefined);
                    setOpenDialog(true);
                },
            });
        }

        return <Toolbar commands={commands} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Email',
                dataIndex: 'email',
                width: 200,
            },
            {
                title: 'Имя',
                dataIndex: 'name',
                width: 200,
            },
            {
                title: 'Компания',
                dataIndex: 'companyName',
                width: 200,
            },
            {
                title: 'Отдел',
                dataIndex: 'depId',
                width: 200,
            },
            {
                title: 'Админ.',
                align: 'center',
                width: 80,
                render: (_: any, record) => {
                    return record.isAdmin ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Уровень',
                align: 'center',
                width: 120,
                render: (_: any, record) => {
                    return enumLabel(record.level);
                },
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        if (userSession.isAdmin) {
            columns.splice(0, 0, {
                align: 'center',
                width: 45,
                render: (_, record) => {
                    return (
                        <Button
                            className='delete-user-btn'
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();

                                Modal.confirm({
                                    title: `Удалить пользователя "${record?.email}"?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                    );
                },
            });
        }

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record: IUser) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            <User
                id={currentEntity?.id}
                openDialog={openDialog}
                onClose={(value: boolean) => {
                    setOpenDialog(false);
                    setRefreshRequired(value);
                }}
            />

            {contextHolder}
        </>
    );
};

export default Companies;
