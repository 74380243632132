import React from "react";

const ImgGetClose = () => {

    return<svg width="32" height="32">
        <g className="svg-select">
            <line stroke-linecap="round" stroke-linejoin="null" y2="20" x2="11" y1="11" x1="21" fill-opacity="null" fill="none" />
            <line stroke-linecap="round" stroke-linejoin="null" y2="20" x2="21" y1="11" x1="11" fill-opacity="null" fill="none"/>
        </g>
    </svg>
}

export default ImgGetClose;