import React, { useState, useEffect } from 'react';

import { Button, Modal, Form, Space, notification, DatePicker, Select, InputNumber } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { NstrKind } from '@src/core/enums/nstr-kind';

import { ICurrencyRate } from '@entities/currency-rate';
import { ICompany } from '@entities/company';
import { IDictionary } from '@entities/dictionary';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ICurrencyRateForm {
    id: number | undefined;
    openDialog: boolean;
    onClose: (value: boolean) => void;
}

const emptyEntity: ICurrencyRate = {
    id: undefined,
    companyId: undefined,
    date: undefined,
    currencyId: undefined,
    rate: undefined,
};

const CurrencyRate = (props: ICurrencyRateForm) => {
    const { id, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const dateFormat = 'DD.MM.YYYY';

    const [entity, setEntity] = useState<ICurrencyRate>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<ICompany>>([]);

    const [loadingCurrencies, setLoadingCurrencies] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<Array<IDictionary>>([]);

    useEffect(() => {
        if (!id || !openDialog) {
            return;
        }

        setLoading(true);

        serverFetch(`currencyrates/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения курса валют', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id, openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingCompanies(true);

        serverFetch('companies', { method: 'GET' })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                setLoadingCompanies(false);
            });
    }, [openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingCurrencies(true);

        serverFetch('nstrs', { method: 'GET', queryParams: { companyId: entity.companyId, nstrKind: NstrKind.Currency } })
            .then((data) => {
                setCurrencies(data);
                setLoadingCurrencies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения валют', ex, () => d(userLoaded(undefined)));
                setLoadingCurrencies(false);
            });
    }, [openDialog]);

    const onFinishSave = () => {
        serverFetch(`currencyrates`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                close(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения курса валют', ex, () => d(userLoaded(undefined)));
            });
    };

    const close = (value: boolean) => {
        setEntity(emptyEntity);
        onClose(value);
    };

    return (
        <>
            {!loading && !loadingCompanies && !loadingCurrencies && (
                <Modal
                    title={`${id ? 'Изменить курс валют' : 'Добавить курс валют'}`}
                    destroyOnClose={true}
                    width={500}
                    open={openDialog}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form preserve={false} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinishSave}>
                        <Form.Item
                            initialValue={entity?.companyId}
                            required
                            label='Компания'
                            name='companyId'
                            rules={[{ required: true, message: 'Выберите компанию' }]}
                        >
                            <Select
                                autoFocus
                                options={
                                    companies
                                        ? companies.map((e) => {
                                              return { value: e.id, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, companyId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.date ? dayjs(entity?.date, dateFormat) : undefined}
                            required
                            label='Дата'
                            name='date'
                            rules={[{ required: true, message: 'Укажите дату' }]}
                        >
                            <DatePicker
                                autoFocus
                                format={dateFormat}
                                onChange={(data) => {
                                    setEntity({ ...entity, date: data });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.currencyId}
                            required
                            label='Валюта'
                            name='currencyId'
                            rules={[{ required: true, message: 'Выберите валюту' }]}
                        >
                            <Select
                                autoFocus
                                options={
                                    currencies
                                        ? currencies.map((e) => {
                                              return { value: e.sprId, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, currencyId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.rate}
                            required
                            label='Ставка'
                            name='rate'
                            rules={[{ required: true, message: 'Укажите ставку' }]}
                        >
                            <InputNumber
                                autoFocus
                                onChange={(value) => {
                                    setEntity({ ...entity, rate: Number(value) });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='link' onClick={() => close(false)}>
                                    Отменить
                                </Button>
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {contextHolder}
        </>
    );
};

export default CurrencyRate;
