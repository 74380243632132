import { Button } from "antd";
import React from "react";

const DialogFooter = ({...props}) => {
    const {onCancel, onOk, loading} = props;

    return<>
        <Button type="default" loading={loading} onClick={onCancel}>Отмена</Button>
        <Button type="primary" loading={loading} onClick={onOk}>OK</Button>
    </>

}

export default DialogFooter;