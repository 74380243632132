import React from 'react';

import { NotificationInstance } from 'antd/es/notification/interface';

const duration: number = 10;

export const exception = (api: NotificationInstance, message: string, ex: any, logout: any) => {
    api.error({
        message: message,
        description: ex && ex.userMessage,
        placement: 'bottomRight',
        duration: duration,
    });

    console.log('ex.statusCode', ex);

    if (ex.status === 401 && logout) logout();
};

export const error = (api: NotificationInstance, message: string, description: string = '') => {
    api.error({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const success = (api: NotificationInstance, message: string, description: string = '') => {
    api.success({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const warning = (api: NotificationInstance, message: string, description: any) => {
    api.warning({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};
