import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stage, Layer } from 'react-konva';
import Konva from 'konva';
import { cadServerFetch, serverFetch } from "../core/server";
import CadElem from "./cad-elem";
import { useStrictMode } from 'react-konva';
import CadToolbar from "./cad-toolbar";
import "./cad.css";
import { Spin, message } from "antd";
import CadMove from "./cad-move";
import { useAppSelector } from "@src/store/hooks";
import { CadSizeX, CadSizeY } from "./cad-size";
import CadProperties from "./cad-properties";
import CadSplit from "./cad-split/cad-split";
import CadSash from "./cad-sash/cad-sash";
import CadOperProps from "./cad-oper-props/cad-oper-props";
import CadMosqnet from "./cad-mosqnet/cad-mosqnet";
import CadSoed from "./cad-soed";

const initObjMoved = { obj: null, oldPosition: null, newPosition: null }
const barSize = 20;

const Cad = ({...props}) => {
    const userSession = useAppSelector((s) => s.userSession);
    useStrictMode(true);
    const { id, onClose } = props;
    const [selections, setSelections] = useState([]);
    const [construction, setConstruction] = useState();
    const [objMoved, setObjMoved] = useState(initObjMoved);
    const [objPropsId, setObjPropsId] = useState();
    const [loading, setLoading] = useState(false);
    const [insertingSplit, setInsertingSplit] = useState(false);
    const [insertingSash, setInsertingSash] = useState(false);
    const [insertingMosk, setInsertingMosk] = useState(false);
    const [typeOfSplit, setTypeOfSplit] = useState([]);
    const [typeOfInteract, setTypeOfInteract] = useState(false);
    const companyId = userSession.companyId;
    const refScene = useRef(null);
    const [insp, setInsp] = useState({ sizes: [true,false,true,false] });
    const [curObjId, setCurObjId] = useState();

    const [size, setSize] = useState({ width: 0, height: 0 });
    const componentRef = useRef();
    useEffect(() => {
        const handleResize = () => {
        const { current } = componentRef;
        if (current) {
            const { width, height } = current.getBoundingClientRect();
            setSize({ width: width - 4, height: height - 4 });
        }
    };
        handleResize(); // вызовите функцию сразу после монтирования компонента
        window.addEventListener('resize', handleResize); // добавить слушатель события изменения размера окна
        return () => {
        window.removeEventListener('resize', handleResize); // убрать слушатель при размонтировании компонента
        };
    }, []);

    const getConstruction = () => {
        setLoading(true);
        cadServerFetch(`cad/Construction/${companyId}/${id}`)
            .then((data) => {
                setConstruction(data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                message.error(e.userMessage);
            })
    }

    useEffect(getConstruction, [id]);
    Konva.autoDrawEnabled = true;

    const sizes = [];
    // Создаем копию массива размеров для манипуляции
    construction?.paintSizes
        // Фильтр по типу размера
        .filter(i => (i.kind == 1 && insp.sizes[0]) || (i.kind == 2 && insp.sizes[1]) || (i.kind == 3 && insp.sizes[2]) || (i.kind == 4 && insp.sizes[3]) )
        .forEach(i => {
            if (!sizes.find(i2 => i2.orientation == i.orientation && i2.startValue == i.startValue && i2.endValue == i.endValue))
                sizes.push({...i});
        });
    // Получить размеры по горизонтали    
    const xSizesX = sizes && sizes
        .filter(i => i.orientation == 1)
        .sort((a,b) => a.startValue === b.startValue ? a.userValue - b.userValue : a.startValue - b.startValue)
    // Распределить размеры по позициям
    let qtyX = -1; // Количество заполненных уровней
    xSizesX && xSizesX.forEach(i => {
        let level = -1;
        // Найдем минимальный уровень на котором размеры не пересекаются
        for (let i2 = 0; i2 <= qtyX; i2++)
            if (!xSizesX.find(i3 => i3.level == i2 && i3.startValue < i.endValue && i3.endValue > i.startValue)){
                level = i2;
                break;
            }
        if (level == -1) level = ++qtyX;
        i.level = level;
    });
    // Получить размеры по вертикали    
    const xSizesY = sizes && sizes
        .filter(i => i.orientation == 2)
        .sort((a,b) => a.startValue === b.startValue ? a.userValue - b.userValue : a.startValue - b.startValue)
    // Распределить размеры по позициям
    let qtyY = -1; // Количество заполненных уровней
    xSizesX && xSizesY.forEach(i => {
        let level = -1;
        // Найдем минимальный уровень на котором размеры не пересекаются
        for (let i2 = 0; i2 <= qtyY; i2++)
            if (!xSizesY.find(i3 => i3.level == i2 && i3.startValue < i.endValue && i3.endValue > i.startValue)){
                level = i2;
                break;
            }
        if (level == -1) level = ++qtyY;
        i.level = level;    
    });

    // Габариты экрана, приходящиеся на изделие без размеров
    const screenSizeX = size.width  - (qtyY + 1)*barSize;
    const screenSizeY = size.height - (qtyX + 1)*barSize;
    // Расчет необходимых масштабов по осям чтобывместить все изделие. Для выбора наименьшего масштаба
    const scaleX = construction ? screenSizeX / construction.width : 1;
    const scaleY = construction ? screenSizeY / construction.height : 1;
    const scale = Math.min(scaleX, scaleY);
    // Смещение нулевой точки изделия для нормальный СК - 0 в левом нижнем углу экрана в координатах экрана
    const x = construction ? (size.width - construction.width*scale - (qtyY + 1)*barSize)/2 : 0;
    const y = construction ? (size.height - construction.height*scale - (qtyX + 1)*barSize)/2 : 0;
    const sceneStyle = { border: "gray 1px solid", width: "100%", height: "100%", padding: 2, borderRadius: 4, position: 'relative' };

    const onSelect = (objId, addSelection) => {
        if (!objId) setSelections([]);
        else {
            setCurObjId(objId);
            const newSelections = addSelection ? [...selections] : [];
            const idx = newSelections.indexOf(objId);
            if (idx === -1) newSelections.push(objId);
            else newSelections.splice(idx,1);
            setSelections(newSelections);
        }
    }

    const onDragStart = (obj) => setObjMoved({...initObjMoved, obj, oldPosition: obj.absolutePosition() })
    const onDragFinish = (obj) => setObjMoved({...objMoved, obj, newPosition: obj.absolutePosition() });
    const onMoveClose = (refresh) => { setObjMoved({...initObjMoved}); refresh && getConstruction(); }
    const onDblClick = (obj) => setObjPropsId(obj.attrs.objId);
    const onPropClose = (refresh) => { setObjPropsId(); refresh && getConstruction(); }
    const onUndo = () => {
        const data = { companyId, constructionId: id };
        cadServerFetch('cad/oper/undo', { method: 'POST', bodyData: data })
            .then(() => getConstruction())
            .catch(e => message.error(e.userMessage));
    }

    const onElemEnter = (obj) => { setCurObjId(obj.attrs.objId); }

    const onSplitClose = (refresh) => { setInsertingSplit(false); refresh && getConstruction(); }

    const onSashClose = (refresh) => { setInsertingSash(false); refresh && getConstruction(); }

    const onMoskClose = (refresh) => { setInsertingMosk(false); refresh && getConstruction();}

    const handleInsertSplit = useCallback((type) => {
        if (selections.length > 0) {
            setTypeOfSplit(type);
            setInsertingSplit(true);
        } else {
            message.warning("Выберите хотя бы один элемент для вставки разделения");
        }
    }, [selections]);

    const handleInsertSash = useCallback((type) => {
        if (selections.length > 0) {
            setTypeOfInteract(type);
            setInsertingSash(true);
        } else {
            message.warning("Выберите хотя бы один элемент для вставки створки");
        }
    }, [selections]);
  
    const handleInsertMosk = useCallback(() => {
        if (selections.length > 0) {
            setInsertingMosk(true);
        } else {
            message.warning("Выберите хотя бы один элемент для вставки москитной сетки");
        }
    }, [selections]);

    const handleDelete = () => {
        if (selections.length > 0) {
            setLoading(true);
            serverFetch('cad/oper/delete', { method: 'POST', bodyData: {companyId, constructionId: id, objectIds: selections} })
                .then(() => getConstruction())
                .catch(e => {
                    message.error(e.userMessage);
                    setLoading(false);
                });
        } else {
            message.warning("Выберите объект для удаления");
        }
    }

    let divOper = null;

    if (objMoved.oldPosition && objMoved.newPosition && objMoved.obj)
        divOper = <CadMove onClose={onMoveClose} oldPosition={objMoved.oldPosition} 
                   newPosition={objMoved.newPosition} objectId={objMoved.obj.attrs.objId} companyId={companyId} constructionId={id} />
    else if (objPropsId)
        divOper = <CadOperProps objectId={objPropsId} companyId={companyId} constructionId={id} onClose={onPropClose} sysId={construction.sysId} 
                   setConstruction={setConstruction}/>
    else if (insertingSplit)
        divOper = <CadSplit onCancel={() => setInsertingSplit(false)} orientation={typeOfSplit} elems={selections} companyId={companyId} constructionId={id}
            sysId={construction.sysId} width = {construction.width} height = {construction.height} setConstruction = {setConstruction}  onClose = {onSplitClose}/>
    else if (insertingSash)
        divOper = <CadSash onCancel={() => setInsertingSash(false)} constructionId={id} objId={parseInt(selections)} companyId={companyId} onClose = {onSashClose} 
                    setConstruction = {setConstruction} mod={typeOfInteract}/>
    else if (insertingMosk)
        divOper = <CadMosqnet constructionId={id} companyId={companyId} objId={parseInt(selections)} onCancel={() => setInsertingMosk(false)} onClose={onMoskClose}/>

    const divElems = construction 
        && construction.elems.map(elem => 
            <CadElem elem={elem} onSelect={onSelect} selections={selections} key={elem.id} scale={scale} 
                onDragStart={onDragStart} onDragFinish={onDragFinish} onDblClick={onDblClick} onEnter={onElemEnter}/>);
    const divLoading = loading && <Spin fullscreen={true}/>
    const divSizesX = xSizesX && xSizesX.map((i,idx) => <CadSizeX item={i} size={barSize/scale} key={`sizex-${idx}`} />);
    const divSizesY = xSizesY && xSizesY.map((i,idx) => <CadSizeY item={i} size={barSize/scale} x0={construction.width } key={`sizey-${idx}`} />);
    
    const divSoeds = construction 
        && construction.paintSoeds.map(i => 
            <CadSoed soed={i} 
                visible={i.id == curObjId || i.object1Id == curObjId || i.object2Id == curObjId || selections.find(j => j == i.id)} 
                selections={selections} scale={scale} 
                onSelect={onSelect} onDblClick={onDblClick} onDragStart={onDragStart} onDragFinish={onDragFinish}
            />);

    return <div className="cad">
        <CadToolbar
            onRefresh={getConstruction}
            onUndo={onUndo}
            onClose={onClose}
            onInsertHorizSplit={() => handleInsertSplit(1)}
            onInsertVertSplit={() => handleInsertSplit(2)}
            onInsertSash={() => handleInsertSash(false)}
            onInsertMosk={() => handleInsertMosk()}
            onViewSash={() => handleInsertSash(true)}
            onDelete={() => handleDelete()}/>
        <div style={sceneStyle} ref={componentRef} id="cad-body">        
            <Stage width={size.width} height={size.height} scaleY={-scale} scaleX={scale} y={screenSizeY - y} x={x} ref={refScene}>
                <Layer>
                    {divElems}
                    {divSizesX}
                    {divSizesY}
                    {divSoeds}
                    {/* <Circle x={0} y={0} radius={5} fill="red" stroke="black" strokeWidth={1}/> */}
                </Layer>
            </Stage>
        </div>
        <CadProperties setItem={setInsp} item={insp} construction={construction} id={id}/>
        {divOper}
        {divLoading}
    </div>
}

export default Cad;