import React, { useState, useEffect } from 'react';

import { Button, Modal, Input, Form, Space, notification, Switch, Select } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { ICompany } from '@entities/company';
import { IDepartment } from '@entities/department';
import { IUserSession } from '@entities/user-session';
import { ICompanyFilter } from '@entities/company-filter';
import { IDepartmentFilter } from '@entities/department-filter';

import { UserLevel, enumLabel } from '@enums/user-level';
import { IEnumItem, getEnumList } from '@enums/enum-item';

interface IUserForm {
    id: number | undefined;
    openDialog: boolean;
    onClose: (value: boolean) => void;
}

const emptyEntity: IUser = {
    id: undefined,
    name: undefined,
    email: undefined,
    password: undefined,
    companyId: undefined,
    depId: undefined,
    isAdmin: false,
    level: undefined,
};

const User = (props: IUserForm) => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entity, setEntity] = useState<IUser>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [loadingDepartments, setLoadingDepartments] = useState<boolean>(false);
    const [departments, setDepartments] = useState<Array<IDepartment>>([]);

    const [userLevels] = useState<Array<IEnumItem>>(getEnumList(UserLevel, enumLabel));

    useEffect(() => {
        if (!id || !openDialog) {
            return;
        }

        setLoading(true);

        serverFetch(`users/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id, openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingCompanies(true);

        let filter: ICompanyFilter = {};
        if (userSession.level >= UserLevel.Manufacturer) {
            filter.id = userSession.companyId;

            let tmpEntity = { ...entity, companyId: userSession.companyId };

            if (userSession.level == UserLevel.Dealer) tmpEntity = { ...tmpEntity, depId: userSession.depId };

            setEntity(tmpEntity);

            loadDepartments(userSession.companyId);
        }

        serverFetch('companies', { method: 'GET', queryParams: filter })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                setLoadingCompanies(false);
            });
    }, [openDialog]);

    const loadDepartments = (companyId?: number) => {
        setDepartments([]);

        if (!companyId) return;

        setLoadingDepartments(true);

        let filter: IDepartmentFilter = {};
        if (userSession.level >= UserLevel.Manufacturer) {
            filter.companyId = userSession.companyId;

            if (userSession.level == UserLevel.Dealer) filter.id = userSession.depId;
        }

        serverFetch('departments', { method: 'GET', queryParams: filter })
            .then((data) => {
                setDepartments(data);
                setLoadingDepartments(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения отделов', ex, () => d(userLoaded(undefined)));
                setLoadingDepartments(false);
            });
    };

    const onFinishSave = () => {
        serverFetch(`users`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                close(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const close = (value: boolean) => {
        setEntity(emptyEntity);
        onClose(value);
    };

    return (
        <>
            {!loading && !loadingCompanies && (
                <Modal
                    title={`${id ? 'Изменить пользователя' : 'Добавить пользователя'}`}
                    destroyOnClose={true}
                    width={500}
                    open={openDialog}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form preserve={false} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinishSave}>
                        <Form.Item
                            initialValue={entity?.email}
                            required
                            label='Email'
                            name='email'
                            rules={[{ required: true, message: 'Укажите email' }]}
                        >
                            <Input
                                autoFocus
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, email: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.name}
                            required
                            label='Имя'
                            name='name'
                            rules={[{ required: true, message: 'Укажите имя' }]}
                        >
                            <Input
                                disabled={!userSession.isAdmin}
                                onChange={(data) => {
                                    setEntity({ ...entity, name: data.target.value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.companyId}
                            required
                            label='Компания'
                            name='companyId'
                            rules={[{ required: true, message: 'Укажите компанию' }]}
                        >
                            <Select
                                disabled={!userSession.isAdmin}
                                options={
                                    companies
                                        ? companies.map((e) => {
                                              return { value: e.id, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, companyId: value });

                                    loadDepartments(value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item initialValue={entity?.depId} label='Отдел' name='depId'>
                            <Select
                                loading={loadingDepartments}
                                disabled={!userSession.isAdmin}
                                options={
                                    departments
                                        ? departments.map((e) => {
                                              return { value: e.id, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, depId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item label='Админ.'>
                            <Switch
                                disabled={!userSession.isAdmin}
                                defaultChecked={entity?.isAdmin}
                                onChange={(value) => {
                                    setEntity({ ...entity, isAdmin: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.level}
                            required
                            label='Уровень'
                            name='level'
                            rules={[{ required: true, message: 'Укажите уровень' }]}
                        >
                            <Select
                                disabled={!userSession.isAdmin}
                                options={userLevels.filter((l) => l.value >= userSession.level)}
                                onChange={(value, option) => {
                                    setEntity({ ...entity, level: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='link' onClick={() => close(false)}>
                                    Отменить
                                </Button>
                                {userSession.isAdmin && (
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Сохранить
                                    </Button>
                                )}
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {contextHolder}
        </>
    );
};

export default User;
