import React from "react";

const ImgGetPropElem = ({...props}) => {
  const {disabled} = props;
  return<svg width="32" height="32">
    <rect className={disabled ? "svg-cons-disabled" : "svg-cons"} rx="4" id="svg_2" height="28" width="28" y="2" x="2" fill="#fff"/>
    <g className={disabled ? "svg-select-disabled" : "svg-select"}>
      <line stroke-linecap="null" stroke-linejoin="null" y2="30" x2="16" y1="2" x1="16" fill="none"/>
      <line stroke-linecap="null" stroke-linejoin="null" y2="23" x2="23" y1="16" x1="23" fill-opacity="null" fill="none" />
      <line stroke-linecap="null" stroke-linejoin="null" y2="14" x2="23" y1="12" x1="23" fill-opacity="null" fill="none" />
    </g>
  </svg>    
}

export default ImgGetPropElem;