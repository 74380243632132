export const getKanvaPoints = (data) => {
    const res = [];
    data.forEach(i => {
        res.push(i.x, i.y);
    });
    return res;
}

export const getOptionsFromIdNameList = (list) => list.map((i) => ({value: i.id, label: `#${i.id} ${i.name}`}));

export const getSizeText = (value) => Number.isInteger(value) ? value.toString() : value.toFixed(1)
export const getPriceText = (value) => {
    if (!value) return '';
    // Преобразуем число в строку с двумя знаками после запятой
    const parts = value.toFixed(2).split('.');    
    // Получаем целую часть и дробную часть
    let integerPart = parts[0];
    const decimalPart = parts[1];    
    // Добавление пробелов для разделения на триады
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");    
    // Объединяем целую и дробную части обратно
    return integerPart + '.' + decimalPart; // Используем запятую между частями
}