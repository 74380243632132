import React from "react";
import './cad-properties.css';
import { Checkbox } from "antd";
import { getPriceText } from "./utils";

const CadProperties = ({...props}) => {
  const {item, setItem, construction, id} = props;

  //console.log(item);

  return<div className="cad-prop">
    <div className="cad-prop-price">{getPriceText(construction?.price)}</div>
    <div># {id}</div>

    <div className="cad-prop-section">
      <div className="cad-prop-title">Размеры</div>
      <Checkbox checked={item.sizes[0]} onChange={(e) => setItem({...item, sizes: [e.target.checked,item.sizes[1],item.sizes[2],item.sizes[3]] })}>Габариты</Checkbox>
      <Checkbox checked={item.sizes[1]} onChange={(e) => setItem({...item, sizes: [item.sizes[0],e.target.checked,item.sizes[2],item.sizes[3]] })}>Створка</Checkbox>
      <Checkbox checked={item.sizes[2]} onChange={(e) => setItem({...item, sizes: [item.sizes[0],item.sizes[1],e.target.checked,item.sizes[3]] })}>Рама</Checkbox>
      {/* <Checkbox checked={item.sizes[3]} onChange={(e) => setItem({...item, sizes: [item.sizes[0],item.sizes[1],item.sizes[2],e.target.checked] })}>Импост</Checkbox> */}
    </div>
  </div>
}

export default CadProperties;