import React from "react";

const ImgGetAlignLight = ({...props}) => {
    const {disabled} = props;
    return<svg width="32" height="32">
        <rect className={disabled ? "svg-cons-disabled" : "svg-cons"} rx="2.0088518" height="24" width="28" y="2" x="2" fill-opacity="null" fill="#fff" />  
        <line className={disabled ? "svg-cons-disabled" : "svg-cons"} stroke-linecap="null" stroke-linejoin="null" y2="26" x2="16" y1="2" x1="16" fill-opacity="null" strokeOpacity="null" fill="none"/>
        <g className={disabled ? "svg-select-disabled" : "svg-select"}>
            <line stroke-linecap="round" y2="16" x2="13" y1="16" x1="5" fill-opacity="null" fill="none" />
            <line stroke-linecap="round" y2="16" x2="19" y1="16" x1="27" fill-opacity="null" fill="none" />
        </g>
    </svg>
}

export default ImgGetAlignLight;