import { Input } from "antd";
import React from "react";
import "./com-soed-coord.css"

const ComSoedCoords = ({...props}) => {
    const { x, y, angle, sizes, setItem } = props;

    return<div className="com-soed-coords">
        <Input value={x} style={{width: 72}} onChange={(e) => setItem({x: e.target.value, y})}/>
        <div>Y</div>
        <Input value={y} style={{width: 72}} onChange={(e) => setItem({y: e.target.value, x})}/>
        <div>Угол</div>
        <Input value={angle} className="input-disabled" style={{width: 48}}/>
        <div>Размер</div>
        <Input value={sizes.join("; ")} className="input-disabled" style={{width: 62}}/>
    </div>
}

export default ComSoedCoords;