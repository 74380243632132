import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Space, Layout, Menu } from 'antd';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { setCurrentMenuItem, userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import '@src/core/index.css';
import { UserLevel } from '@enums/user-level';

const MainLayout = () => {
  const { Header, Content, Sider } = Layout;
  const userSession = useAppSelector<IUserSession>((s) => s.userSession);
  const location = useLocation();
  const d = useAppDispatch();
  const currentMenuItem = useAppSelector<string>((s) => s.currentMenuItem);
  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(false);

  function getItem(label: any, key: string, urlTo?: string, icon?: any, children?: Array<any>, onClick?:any) {
    let itemlabel = urlTo ? <Link to={urlTo}>{label}</Link> : <div onClick={onClick}>{label}</div>;
    return { key, icon, children, label: itemlabel, };
  }

  useEffect(() => {
    let items = location.pathname.split('/');
    d(setCurrentMenuItem(items[1]));
  }, []);

  const onLogout = () => {
    serverFetch(`auth/logout`, { method: 'POST' })
      .then(() => d(userLoaded(undefined)))
      .catch(() => d(userLoaded(undefined)));
  };

  const items = [];

  if (userSession.level == UserLevel.Site) items.push(getItem('Компании', 'companies', '/companies'));
  else if (userSession.level == UserLevel.Manufacturer) items.push(getItem('Компания', 'company', `/company/${userSession.companyId}`));

  if (userSession.level == UserLevel.Dealer) items.push(getItem('Отдел', 'department', `/department/${userSession.depId}`));
  else items.push(getItem('Отделы', 'departments', '/departments'));

  items.push(getItem('Пользователи', 'users', '/users'));

  if (userSession.isAdmin && userSession.level === UserLevel.Site && false) {
    items.push(
      getItem('Справочники', 'dictionaries', '/dictionaries'),
      getItem('Цены', 'prices', '/prices'),
      getItem('Курс валют', 'currencyrates', '/currencyrates')
    );
  }

  if (userSession.isAdmin || userSession.level == UserLevel.Dealer) items.push(getItem('Заказы', 'orders', '/orders'));
  if (userSession) items.push(getItem(`Выход`, 'exit', '', null, undefined, onLogout))

  const onMenuClick = (e: any) => { d(setCurrentMenuItem(e.key)); };

  const userMenuItems = [
    getItem(
      <Space style={{ cursor: 'pointer' }}>
        <div className='user'>{userSession.name}</div>
      </Space>,
      'account',
      undefined,
      undefined,
      [getItem('Личный кабинет', '', undefined, undefined)]
    ),
  ];

  const getLogo = () => {
    return (
      <div className='logo' style={{ marginTop: 20 }}>
        <div>{siderCollapsed ? 'IO' : 'ИТ Окна'}</div>
          <div style={{fontSize: 14, fontWeight: 300}}>{userSession?.email}</div>
        </div>
    );};

  return (
    <Layout style={{ height: '100vh', minHeight: '100vh' }}>
      <Sider
        collapsible={false}
        onCollapse={(e) => setSiderCollapsed(e)}
        width={250}
        style={{ overflow: 'auto', height: '100vh', }}
      >
        {getLogo()}
        <Menu defaultSelectedKeys={[currentMenuItem]} mode='inline' items={items} onClick={onMenuClick} />                
      </Sider>
      <Layout>
        <Content className='main-content'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;