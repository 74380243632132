import { Button, Select } from "antd";
import { cadServerFetch } from "@src/core/server";
import React, { useEffect, useState } from "react";
import ComSelectZapolnColor from "./com-select-zapoln-mat-color";
import "./com-soed-coord.css"

const kindTypes = new Map([
    [0, "Газозаполнение"],
    [1, "Стекло"],
    [2, "Рамка"],
    [3, "Пленка"]
    ]);

const ComSelectZapolnElem = ({ elem, index, len, companyId, onDelete, onMoveUp, onMoveDown, onChange}) => {
    const [avalTypicalCnts, setAvalTypicalCnts] = useState([]);
    const [avalMaterials, setAvalMaterials] = useState([]);
    
    useEffect(() => {
        cadServerFetch(`settings/ZapolnElems?companyId=${companyId}`)
        .then(data => {
            setAvalTypicalCnts(data);
            const avalElemOnKind = data.filter(z => z.kind === elem.kind);
            setAvalMaterials(avalElemOnKind.map(e => ({ id: e.mat.id, name: e.mat.name, art: e.mat.art , value: e.mat.id, label: e.mat.name })));
        })
        .catch(() => console.log("Ошибка получения заполнений"));
        
    }, [companyId, elem.kind]);

    const handleKindChange = (value) => {
        const avalElemOnKind = avalTypicalCnts.filter(z => z.kind === value);
        setAvalMaterials(avalElemOnKind.map(e => ({ id: e.mat.id, name: e.mat.name, art: e.mat.art , value: e.mat.id, label: e.mat.name})));
        elem = { ...elem, kind: value, mat: avalElemOnKind[0].mat, color: { id: null, name: "Выберите новый цвет", vid: 0 } };
        const updatedElem = { ...elem};
        onChange(updatedElem);
    };

    const kindOptions = Array.from(kindTypes.entries()).map(([value, label]) => ({ value, label }));
    return (
        <div className="oper-props-body">
            <div className="oper-props-item oper-props-buttons">
                <Button type="primary" style={{width: 120}}  onClick={onDelete}> Удалить </Button>
                <Button type="primary" style={{width: 120}} onClick={onMoveUp} disabled={index === 0} > Вверх</Button>
                <Button type="primary" style={{width: 120}} onClick={onMoveDown} disabled={index === len - 1}> Вниз</Button>
            </div>
            <div className="oper-props-item">
                <div className="oper-props-item-title">Элемент {index+1}</div>
                <div className="oper-props-item-select">
                    <Select
                        value={elem.kind}
                        options={kindOptions}
                        style={{ width: 416 }}
                        onChange={handleKindChange}
                    />
                </div>
            </div>
            <div className="oper-props-item">
                <div className="oper-props-item-title">Материал</div>
                <div className="oper-props-item-select">
                    <Select
                        value={elem.mat.name}
                        className="input-disabled"
                        options={avalMaterials}
                        onChange={(value) => {
                            const newMat = avalMaterials.find(mat => mat.value === value);
                            onChange({ ...elem, mat: { id: newMat.id, name: newMat.label, art: newMat.art } });
                        }}
                        style={{ width: 416 }}
                    />
                </div>
            </div>
            <div className="oper-props-item">
                <div className="oper-props-item-title">Цвет</div>
                <div className="oper-props-item-select">
                    <ComSelectZapolnColor elem={elem} companyId={companyId} onChange={onChange}/>
                </div>
            </div>
        </div>
)};
        

export default ComSelectZapolnElem;