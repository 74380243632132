import React, { useCallback } from 'react';
import { Form, Select, Button, Input, List, message } from 'antd';

const orientSplitOptions = new Map([
  [1, "Горизонтальная"],
  [2, "Вертикальная"]
]);

const CadSplitFirstTab = ({ orientation, width, height, coordinate, setCoordinate, straightOrient, setStraightOrient, coordinatesList,
  setCoordinatesList, selectedCoordinate, setSelectedCoordinate }) => {

  const initialValues = {
    OrientSplit: orientation,
    InputCoord: straightOrient === 1 ? height / 2 : width / 2,
  };

  const validateXCoordinate = (x) => x >= 0 && x <= width;
  const validateYCoordinate = (y) => y >= 0 && y <= height;

  const handleAdd = () => {
    if (coordinate && !coordinatesList.includes(coordinate)) {
      switch (straightOrient) {
        case 1:
          if (validateYCoordinate(coordinate)) {
            setCoordinatesList([...coordinatesList, parseFloat(coordinate).toFixed(1)]);
            setCoordinate('');
          } else {
            message.warning('Координата выходит за пределы изделия!');
          }
          break;
        case 2:
          if (validateXCoordinate(coordinate)) {
            setCoordinatesList([...coordinatesList, parseFloat(coordinate).toFixed(1)]);
            setCoordinate('');
          } else {
            message.warning('Координата выходит за пределы изделия!');
          }
          break;
        default:
          return;
      }
    }
  };

  const handleDelete = () => {
    if (selectedCoordinate !== null) {
      const newCoordinatesList = coordinatesList.filter((item) => item !== selectedCoordinate);
      setCoordinatesList(newCoordinatesList);
      setSelectedCoordinate(null);
    }
  };

  const handleClear = () => {
    setCoordinatesList([]);
    setSelectedCoordinate(null);
  };

  const handleOrientSplitChange = useCallback(
    (value) => {
      setStraightOrient(value);
      handleClear();
      if (value === 1) {
        setCoordinate(height / 2);
      } else if (value === 2) {
        setCoordinate(width / 2);
      }
    },
    [straightOrient]
  );

  const orientOptions = Array.from(orientSplitOptions.entries()).map(([value, label]) => ({ value, label }));

  return (
    <Form initialValues={initialValues} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} size="small">
      <Form.Item label="Ориентация вставки" name="OrientSplit" colon={false}>
        <Select
          options={orientOptions}
          onChange={handleOrientSplitChange}
        />
      </Form.Item>
      <Form.Item label="Позиция" name="PosSplit" colon={false}>
        <Input
          name="InputCoord"
          value={parseFloat(coordinate).toFixed(1)}
          defaultValue={parseFloat(initialValues.InputCoord).toFixed(1)}
          onChange={(e) => setCoordinate(e.target.value)}
          style={{width: 109}}
        />
      </Form.Item>
      <div>
      <Button onClick={handleAdd} style={{ marginRight: '10px' }}> Добавить </Button>
        <Button onClick={handleDelete} disabled={selectedCoordinate === null} style={{ marginRight: '10px' }}> Удалить </Button>
        <Button onClick={handleClear} disabled={coordinatesList.length === 0}> Очистить </Button>
      </div>
      <List
        style={{ marginTop: '10px', marginBottom: '20px'}}
        bordered
        dataSource={coordinatesList}
        renderItem={(item) => (
          <List.Item
            onClick={() => setSelectedCoordinate(item)}
            style={{
              cursor: 'pointer',
              backgroundColor: selectedCoordinate === item ? '#e6f7ff' : 'white',
            }}
          >
            {item}
          </List.Item>
        )}
      />
    </Form>
  );
};

export default CadSplitFirstTab;