import { cadServerFetch } from "@src/core/server";
import DialogFooter from "./dialogs/dialog-footer";
import { Form, Input, Modal } from "antd";
import React, { useState } from "react";

const CadMove = ({...props}) => {
    const { onClose, oldPosition, newPosition, objectId, companyId, constructionId } = props;
    const [form] = Form.useForm();
    const direction = Math.abs(oldPosition.x - newPosition.x) > Math.abs(oldPosition.y - newPosition.y) ? 'x' : 'y';
    const [delta, setDelta] = useState(direction == 'x' ? newPosition.x - oldPosition.x : oldPosition.y - newPosition.y);
    const [loading, setLoading] = useState(false);

    const onFinish = () => {
        const data = { companyId: Number(companyId), constructionId, objectId, delta: Number(delta), direction }
        setLoading(true);
        cadServerFetch('cad/oper/move', { method: 'POST', bodyData: data })
            .then(() => onClose(true))
            .catch(e => {
                console.log(`Ошибка ${e.message}`);
                setLoading(false);
            });
    }

    return<Modal forceRender
        title={`Перемещение # ${objectId}`}
        open={true}
        showTitle={true}
        closable={false}
        closeOnOutsideClick={false}
        //footer={null}
        width={340}
        onCancel={() => onClose()}
        onOk={onFinish}
        loading={loading}
        footer={<DialogFooter onCancel={() => onClose()} onOk={onFinish} loading={loading}/>}
    >
        <Form colon={false} form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
            <Form.Item label="Размер"><Input value={delta} onChange={(e) => setDelta(e.target.value)} disabled={loading}/></Form.Item>
        </Form>
    </Modal>

}

export default CadMove;