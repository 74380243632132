import { cadServerFetch } from "@src/core/server";
import { Form, Modal, Tabs, message } from "antd";
import React, { useState } from "react";
import CadOperPropsElem from "./cad-oper-props-elem";
import CadOperPropsZapoln from "./cad-oper-props-zapoln";
import './cad-oper-props.css';
import DialogFooter from "../dialogs/dialog-footer";
import CadSpec from "../cad-spec/cad-spec";
import CadParams from "../cad-params";
import CadBasaRsh from "../cad-basa-rsh";

const CadOperProps = ({...props}) => {
    const { onClose, objectId, companyId, constructionId, sysId, setConstruction } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState();

    const getItem = () => {
        setLoading(true);
        cadServerFetch(`cad/oper/object?companyId=${companyId}&constructionId=${constructionId}&objectId=${objectId}`)
            .then((data) => {
                setItem(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error(`Ошибка ${e.message}`);
                setLoading(false);
            })
    }

    useState(getItem, []);

    const onFinish = () => {
        switch(item.vid){
            case 2:
                cadServerFetch(`cad/oper/elem/${companyId}/${constructionId}`, { method: 'POST', bodyData: item } )
                    .then(data => {
                        setConstruction(data);
                        onClose(true);
                    })
                    .catch(e => console.log(e.message));
            break;
            case 3:
                let isEntered = true;
                
                for (const elem of item.cnts) {
                    if (!elem.kind || !elem.mat.id || !elem.color.id) {
                        isEntered = false;
                    }
                }

                if (!isEntered) {
                    message.error("Пожалуйста, заполните все поля!");
                    return;
                }

                cadServerFetch(`cad/oper/zapoln/${companyId}/${constructionId}`, { method: 'POST', bodyData: item })
                    .then(data => {
                        setConstruction(data);
                        onClose(true);
                    })
                    .catch(e => console.log(e.message));
            break;
        }
    }

    let divBody = <div/>;
    if (item)
    switch(item.vid){
        case 2:
            divBody = <CadOperPropsElem item={item} companyId={companyId} sysId={sysId} setItem={setItem} />
            break;
        case 3:
            divBody = <CadOperPropsZapoln item={item} companyId={companyId} sysId={sysId} setItem={setItem} />
            break;
    }

    const items = [
        {key: 1, label: 'Основные', children: divBody},
        {key: 2, label: 'Спецификация', children: <CadSpec ids={[objectId]} constructionId={constructionId} />},
        {key: 3, label: 'Параметры', children: <CadParams objectId={objectId} constructionId={constructionId} />},
        {key: 4, label: 'Базы расчета', children: <CadBasaRsh objectId={objectId} constructionId={constructionId} />},
    ]

    return<Modal forceRender
        title={`${item?.name ?? "Загрузка..."} # ${objectId}`}
        open
        showTitle
        closable={false}
        closeOnOutsideClick={false}
        //footer={null}
        width={800}
        onCancel={() => onClose()}
        onOk={onFinish}
        loading={loading}
        footer={<DialogFooter onCancel={() => onClose()} onOk={onFinish} loading={loading}/>}
    >
        <Form colon={false} form={form}>
            <Tabs items={items}></Tabs>
        </Form>
    </Modal>
}

export default CadOperProps;