import React, { useRef, useState } from 'react';

import { Button, Modal, notification } from 'antd';

import { exception } from '@extensions/notification';

import { serverFetch } from '@src/core/server';

interface IDialogProps {
    onCancel: () => void;
    onLoadedFile: () => void;
    open: boolean;
}

const ImportDictionaryControl = (props: IDialogProps) => {
    const { onCancel, onLoadedFile, open } = props;

    const [api, contextHolder] = notification.useNotification();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <Modal
                width={500}
                title={'Импорт справочника'}
                open={open}
                onCancel={() => {
                    onCancel();
                }}
                footer={[
                    <Button
                        key={'selectBtn'}
                        type='primary'
                        disabled={loading}
                        onClick={() => {
                            if (fileInputRef && fileInputRef.current) {
                                fileInputRef.current.click();
                            }
                        }}
                    >
                        Открыть
                    </Button>,
                    <Button
                        key={'cancelBtn'}
                        disabled={loading}
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Отмена
                    </Button>,
                ]}
            >
                Пожалуйста выберите .xml файл для импорта.
            </Modal>

            <input
                type='file'
                accept='.xml'
                onChange={(x) => {
                    if (x.target.files) {
                        let file = x.target.files[0];

                        if (file) {
                            const reader = new FileReader();

                            reader.readAsDataURL(file);
                            reader.onload = () => {
                                let fileBody = reader?.result as string;

                                setLoading(true);

                                serverFetch('nstrs/import', { method: 'PUT', bodyData: fileBody })
                                    .then(() => {
                                        setLoading(false);
                                        onLoadedFile();
                                    })
                                    .catch((ex) => {
                                        exception(api, 'Ошибка импорта', ex, () => {});
                                        setLoading(false);
                                    });
                            };

                            reader.onerror = (error) => console.log(error);
                        }
                    }
                }}
                onClick={(event) => {
                    (event.target as HTMLInputElement).value = '';
                }}
                multiple={false}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            {contextHolder}
        </>
    );
};

export default ImportDictionaryControl;
