import { Descriptions, DescriptionsProps, Space } from "antd";
import React from "react";

const OrderTotal = (props:any) => {
    const { loading, item } = props;
    const clientInfo: DescriptionsProps['items'] = [
        { key: '1', label: <b>Общая стоимость заказа к оплате</b>, children: `${item.syma1}`, },
        { key: '2', label: 'Стоимость заказа в ценах закупа', children: `${item.syma1}`, },
        { key: '3', label: 'Оплачено клиентом', children: `${item.syma1}`, },
        { key: '4', label: <b>Долг клиента по оплате</b>, children: `${item.syma1}`, },
        { key: '5', label: '', children: '', },
        { key: '6', label: 'Выполнено', children: `${item.syma1}`, },
        { key: '7', label: <b>Остаток</b>, children: `${item.syma1}`, },
    ];

    const companyInfo: DescriptionsProps['items'] = [
        { key: '1', label: '', children: '', },
        { key: '2', label: 'Стоимость поставки', children: `${item.syma1}`, },
        { key: '3', label: 'Оплачено', children: `${item.syma1}`, },
        { key: '4', label: <b>Долг</b>, children: `${item.syma1}`, },
        { key: '5', label: '', children: '', },
        { key: '6', label: 'Получено', children: `${item.syma1}`, },
        { key: '7', label: <b>Остаток</b>, children: `${item.syma1}`, },
    ];

    const anotherCompanyInfo: DescriptionsProps['items'] = [
        { key: '1', label: '', children: '', },
        { key: '2', label: 'Стоимость поставки', children: `${item.syma1}`, },
        { key: '3', label: 'Оплачено', children: `${item.syma1}`, },
        { key: '4', label: <b>Долг</b>, children: `${item.syma1}`, },
        { key: '5', label: '', children: '', },
        { key: '6', label: 'Получено', children: `${item.syma1}`, },
        { key: '7', label: <b>Остаток</b>, children: `${item.syma1}`, },
    ];

    return (
        <div className="order-total">
            <div className="order-total-syma">Итого: {item.syma2}</div>
        </div>
        // <Space>
        //     <Descriptions title='Клиент' column={1} size='small' items={clientInfo} />
        //     <Descriptions title='ООО Лучший производитель окон' column={1} size='small' items={companyInfo} />
        //     <Descriptions title='Другие поставщики' column={1} size='small' items={anotherCompanyInfo} />
        // </Space>
    );
}

export default OrderTotal;