import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteFilled, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import Price from '@components/prices/price';

import { enumLabel } from '@src/core/enums/user-level';
import { IPrice } from '@entities/price';

import './prices.css';

const Prices = () => {
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<IPrice>>([]);
    const [currentEntity, setCurrentEntity] = useState<IPrice>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setCurrentEntity(undefined);
        setEntities([]);

        setLoading(true);

        serverFetch('prices', { method: 'GET' })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения цен', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = (id: number) => {
        serverFetch(`prices/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления цены', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            setCurrentEntity(undefined);
                            setOpenDialog(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IPrice> = [
            {
                align: 'center',
                width: 45,
                render: (_, record) => {
                    return (
                        <Button
                            className='delete-price-btn'
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            onClick={(e) => {
                                e.stopPropagation();

                                Modal.confirm({
                                    title: `Удалить цену?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                    );
                },
            },
            {
                title: 'Материал',
                dataIndex: 'sprName',
                width: 200,
            },
            {
                title: 'Ценовая группа',
                dataIndex: 'colorPriceName',
                width: 200,
            },
            {
                title: 'Уровень',
                width: 150,
                render: (_, record) => {
                    return enumLabel(record.level);
                },
            },
            {
                title: 'Цена',
                dataIndex: 'price',
                width: 200,
            },
            {
                title: 'Компания',
                dataIndex: 'companyName',
                width: 200,
            },
            {
                title: '',
                dataIndex: '',
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: IPrice) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            <Price
                id={currentEntity?.id}
                openDialog={openDialog}
                onClose={(value: boolean) => {
                    setOpenDialog(false);
                    setRefreshRequired(value);
                }}
            />

            {contextHolder}
        </>
    );
};

export default Prices;
