import React from 'react';

import { Space, Button, Tooltip } from 'antd';

import './toolbar.css';
import { ButtonType } from 'antd/es/button';

export interface ICommand {
    key: string;
    label: string;
    disabled?: boolean;
    type?: ButtonType;
    icon?: React.ReactNode;
    style?: any;
    onClick: () => void;
}

interface IToolbar {
    title?: string;
    commands: Array<ICommand>;
    farCommands?: Array<ICommand>;
}

const Toolbar = (props: IToolbar) => {
    return (
        <div className='commands-container'>
            
            <Space>                
                {props.commands.map((item) => {
                    return (
                        <Tooltip key={item.key} placement='top' title={item.label}>
                            <Button
                                disabled={item.disabled}
                                type={item.type}
                                icon={item.icon}
                                style={item.style}
                                onClick={item.onClick}
                                size='middle'
                            ></Button>
                        </Tooltip>
                    );
                })}
                {props.title && <div className='toolbar-title'>{props.title}</div>}
            </Space>

            <Space>
                {props.farCommands?.map((item) => {
                    return (
                        <Tooltip key={item.key} placement='left' title={item.label}>
                            <Button
                                disabled={item.disabled}
                                type={item.type}
                                icon={item.icon}
                                style={item.style}
                                onClick={item.onClick}
                                size='middle'
                            ></Button>
                        </Tooltip>
                    );
                })}
            </Space>
        </div>
    );
};

export default Toolbar;
