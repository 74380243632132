import { Select, Modal, Form, Button, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { cadServerFetch } from "@src/core/server";

const { Option } = Select;

const CadMosqnet = ({...props}) => {
    const {constructionId, companyId, objId, onCancel, onClose} = props;
    const [netVars, setNetVars] = useState();
    const [moskId, setMoskId] = useState(0);
    const [moskLoaded, setMoskLoaded] = useState(false);
    const [paramData, setParamData] = useState([]);
    const [selectedParamNet, setSelectedParamNet] = useState();

    const paramColumns = [
        { title: 'Наименование', dataIndex: 'name', key: 'name'},
        { title: 'Значение', dataIndex: 'values', key: 'values',
            render: (values, record) => {
                const selectedValue = selectedParamNet?.find(param => param.key === record.id)?.value || record.defaultId;
    
                return (
                    <Select
                        value={selectedValue}
                        style={{ width: '100%' }} 
                        onChange={(value) => handleParamSelectChange(value, record.id)}
                        disabled={record.isHidden}
                    >
                        {values.map(value => (
                            <Option key={value.id} value={value.id}>{value.name}</Option>
                        ))}
                    </Select>
                );
            }
        }
    ];

    useEffect(() => {
        cadServerFetch(`Cad/Oper/Mosk/Get?constructionId=${constructionId}&objId=${objId}`)
        .then((data) => {
            if (!data) return;
            setMoskId(data.moskId);
            const initialValues = data.map(item => ({
                key: item.key,
                value: item.value
            }));
            setSelectedParamNet(initialValues);
            console.log(data)
        })
        .catch(() => console.log("Ошибка получения информации о москитной сетке"));
    }, [constructionId]); 

    useEffect(() => {
        console.log(moskId)
        cadServerFetch(`Cad/Oper/Mosk/Var?constructionId=${constructionId}`)
        .then((data) => {
            if (!data) return;
            setNetVars(data);
            setMoskLoaded(true);
        })
        .catch(() => console.log("Ошибка получения базовой информации о москитных сетках"));
    }, [moskId])

    useEffect(() => {
        if (moskLoaded && moskId != null) {
            cadServerFetch(`Cad/Oper/Mosk/Param?constructionId=${constructionId}&moskId=${moskId}`)
            .then((data) => {
                setParamData(data);
            })
            .catch(() => console.log("Ошибка получения параметров москитной сетки"))
        }
    }, [moskId, moskLoaded])

    if (!netVars || ((moskId == null) ^ (!parseInt(moskId)))) {
        return null;
    }
    const handleSelectMosk = (value) => {
        setMoskId(value);
    };

    const handleParamSelectChange = (value, key) => {
        setSelectedParamNet(prev => {
            const updatedValues = prev.filter(item => item.key !== key);
            updatedValues.push({ key, value });
            return updatedValues;
        });
    };

    const onInsertMosk = (withoutNet) => {
        if (!constructionId) {
            message.error('Пожалуйста, выберите вариант сетки.');
            return;
        }
        const netBody = {
            companyId: parseInt(companyId),
            constructionId: constructionId,
            objectIds: [objId],
            moskId: withoutNet ? moskId : null,
            paramValues: withoutNet ? selectedParamNet : []
        }

        cadServerFetch(`cad/oper/mosk/mod`, { method: 'POST', bodyData: netBody})
                .then((data) => {
                    onClose(true);
                })
                .catch((ex) => {
                    message.error(`Ошибка вставки сетки: ${ex.message}`);
        });
    };

    const moskOptions = netVars.map(i => ({ value: i.id, label: i.name }));

    return (
        <Modal open={true} onCancel={onCancel} forceRender closable={false} footer={null} getContainer={false} width={800} title="Вставка москитной сетки">
            <Form initialValues={{varNet: moskId}}>
                <Form.Item style={{ marginLeft: '54px' }} label="Вариант москитной сетки" name="varNet" colon={false}>
                    <Select options={moskOptions} value={moskId} onSelect={handleSelectMosk} />
                </Form.Item>

                <Table dataSource={paramData} columns={paramColumns} pagination={false} className="parameter-table" scroll={{ y: 462 }} />

                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: "15px" }} colon={false}>
                    <Button htmlType="button" onClick={onCancel}>Cancel</Button>
                    <Button htmlType="button" style={{ marginLeft: "10px" }} onClick={() => onInsertMosk(false)}>Без москитной сетки</Button>
                    <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} onClick={() => onInsertMosk(true)}>OK</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CadMosqnet;