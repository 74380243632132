import React, { useState, useEffect } from 'react';

import { Button, Modal, Form, Space, notification, Select, InputNumber } from 'antd';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { UserLevel, enumLabel } from '@src/core/enums/user-level';
import { NstrKind } from '@src/core/enums/nstr-kind';

import { IPrice } from '@entities/price';
import { ICompany } from '@entities/company';
import { IDictionary } from '@entities/dictionary';

interface IPriceForm {
    id: number | undefined;
    openDialog: boolean;
    onClose: (value: boolean) => void;
}

const emptyEntity: IPrice = {
    id: undefined,
    sprId: undefined,
    colorPriceId: undefined,
    level: undefined,
    price: undefined,
    companyId: undefined,
};

const Price = (props: IPriceForm) => {
    const { id, openDialog, onClose } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [entity, setEntity] = useState<IPrice>(emptyEntity);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<ICompany>>([]);

    const [loadingMaterials, setLoadingMaterials] = useState<boolean>(false);
    const [materials, setMaterials] = useState<Array<IDictionary>>([]);

    const [loadingPricesGroups, setLoadingPricesGroups] = useState<boolean>(false);
    const [pricesGroups, setPricesGroups] = useState<Array<IDictionary>>([]);

    useEffect(() => {
        if (!id || !openDialog) {
            return;
        }

        setLoading(true);

        serverFetch(`prices/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения цены', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [id, openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingCompanies(true);

        serverFetch('companies', { method: 'GET' })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                setLoadingCompanies(false);
            });
    }, [openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingMaterials(true);

        serverFetch('nstrs', { method: 'GET', queryParams: { companyId: entity.companyId, nstrKind: NstrKind.Material } })
            .then((data) => {
                setMaterials(data);
                setLoadingMaterials(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения материалов', ex, () => d(userLoaded(undefined)));
                setLoadingMaterials(false);
            });
    }, [openDialog]);

    useEffect(() => {
        if (!openDialog) {
            return;
        }

        setLoadingPricesGroups(true);

        serverFetch('nstrs', { method: 'GET', queryParams: { companyId: entity.companyId, nstrKind: NstrKind.PriceGroup } })
            .then((data) => {
                setPricesGroups(data);
                setLoadingPricesGroups(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения ценовых групп', ex, () => d(userLoaded(undefined)));
                setLoadingPricesGroups(false);
            });
    }, [openDialog]);

    const onFinishSave = () => {
        serverFetch(`prices`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                close(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения цены', ex, () => d(userLoaded(undefined)));
            });
    };

    const close = (value: boolean) => {
        setEntity(emptyEntity);
        onClose(value);
    };

    return (
        <>
            {!loading && !loadingCompanies && !loadingMaterials && !loadingPricesGroups && (
                <Modal
                    title={`${id ? 'Изменить цену' : 'Добавить цену'}`}
                    destroyOnClose={true}
                    width={500}
                    open={openDialog}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form preserve={false} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinishSave}>
                        <Form.Item
                            initialValue={entity?.companyId}
                            required
                            label='Компания'
                            name='companyId'
                            rules={[{ required: true, message: 'Выберите компанию' }]}
                        >
                            <Select
                                autoFocus
                                options={
                                    companies
                                        ? companies.map((e) => {
                                              return { value: e.id, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, companyId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.sprId}
                            required
                            label='Материал'
                            name='sprId'
                            rules={[{ required: true, message: 'Выберите материал' }]}
                        >
                            <Select
                                autoFocus
                                options={
                                    materials
                                        ? materials.map((e) => {
                                              return { value: e.sprId, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, sprId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.colorPriceId}
                            required
                            label='Ценовая группа'
                            name='colorPriceId'
                            rules={[{ required: true, message: 'Выберите ценовую группу' }]}
                        >
                            <Select
                                autoFocus
                                options={
                                    pricesGroups
                                        ? pricesGroups.map((e) => {
                                              return { value: e.sprId, label: e.name };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setEntity({ ...entity, colorPriceId: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.level}
                            required
                            label='Уровень'
                            name='level'
                            rules={[{ required: true, message: 'Выберете уровень' }]}
                        >
                            <Select
                                autoFocus
                                options={[
                                    { value: UserLevel.Site, label: enumLabel(UserLevel.Site) },
                                    { value: UserLevel.Manufacturer, label: enumLabel(UserLevel.Manufacturer) },
                                    { value: UserLevel.Dealer, label: enumLabel(UserLevel.Dealer) },
                                ]}
                                onChange={(value) => {
                                    setEntity({ ...entity, level: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={entity?.price}
                            required
                            label='Цена'
                            name='price'
                            rules={[{ required: true, message: 'Укажите цену' }]}
                        >
                            <InputNumber
                                autoFocus
                                onChange={(value) => {
                                    setEntity({ ...entity, price: Number(value) });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='link' onClick={() => close(false)}>
                                    Отменить
                                </Button>
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {contextHolder}
        </>
    );
};

export default Price;
