import { DeleteFilled, LoadingOutlined, PlusOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import IzdCreate from "@src/cad/izd-create-new";
//import { IzdCreate } from "@src/cad";
import Toolbar, { ICommand } from "@src/core/controls/toolbar/toolbar";
import { IOrderItem, IUserSession } from "@src/core/entities";
import { serverFetch } from "@src/core/server";
import { useAppSelector } from "@src/store/hooks";
import { Button, message, Modal } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import OrdersEdit from "./orders-edit";
import session from "redux-persist/lib/storage/session";

const OrderItems = (props:any) => {
    const { items, loading, onCad, orderId, onRefresh, disabled } = props;
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [currentProduct, setCurrentProduct] = useState<IOrderItem>();
    const [openProductDialog, setOpenProductDialog] = useState(false);
    const [editingItem, setEditingItem] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(0);

    const onDelete = (id: number) => {
        serverFetch(`orders/item/${id}`, {method: 'DELETE'})
            .then(onRefresh)
            .catch(e => message.error(e.message));
    };

    
    let divOper = null;

    let commands: Array<ICommand> = [];
    
    //if (userSession.isAdmin) {
    if (!disabled)
        commands.push({
            label: 'Добавить',
            key: 'add',
            type: 'primary',
            icon: <PlusOutlined />,
            onClick: () => {
                setCurrentProduct(undefined);
                setOpenProductDialog(true);
            },
        });

        
    //}

    const renderIzd = (value:string, record:IOrderItem) => {
        return <>
            <div dangerouslySetInnerHTML={{ __html: value }} className="item-img" />
            <div className="order-item-ordno">{record.posNumber}</div>
        </>
    }

    const renderSys = (value:any, record:IOrderItem, index: number) => {
        return<div>
            <div>{record.sysName}</div>
            <div>{record.colorName}</div>
        </div>
    }

    const renderQty = (value:any, record:IOrderItem, index: number) => {
        return<div>
            <div>{record.w} x {record.h}</div>
            <div className="order-item-syma">{record.qty} шт</div>
            <div className="order-item-syma">{record.syma2} ₽</div>
        </div>
    }

    const renderName = (value:any, record:IOrderItem, index: number) => {
        return<div>
            <div># {record.id}</div>
            <div>{record.name}</div>
            <div>{record.comment}</div>
        </div>
    }

    const columns: ColumnsType<IOrderItem> = [
        //{ title: '#', dataIndex: 'id', width: 50, },
        { title: "Изделие", dataIndex: 'svg', width: 200, render: renderIzd, },
        { title: 'Система', dataIndex: 'profile', render: renderSys },
        // { title: 'Заполнение', dataIndex: 'zapoln', width: 80, },
        // { title: 'Цвет', dataIndex: 'color', width: 80, },
        { title: 'Дополнительно', dataIndex: 'vstId' },
        // { title: 'Монтаж', dataIndex: 'montId', width: 80, },
        // { title: 'Сложность', dataIndex: 'diffId', width: 80, },
        { title: 'Описание', dataIndex: 'name', render: renderName },
        //{ title: 'Комментариай', dataIndex: 'comment', width: 80, },
        { title: 'Итого', dataIndex: 'qty', width: 120, render: renderQty, align: 'right' },
    ];

    if (userSession.isAdmin) {
        columns.splice(0, 0, {
            align: 'center',
            width: 45,
            render: (_, record) => {
                return (
                    <div>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<DeleteFilled />}
                            disabled={disabled}
                            onClick={(e) => {
                                e.stopPropagation();
    
                                Modal.confirm({
                                    title: `Удалить Изделие?`,
                                    okType: 'primary',
                                    icon: <QuestionCircleOutlined />,
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        record.id && onDelete(record.id);
                                    },
                                });
                            }}
                        />
                        <Button
                            type='text'
                            shape='circle'
                            icon={<EditOutlined />}
                            disabled={disabled}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditingItem(true);
                                setCurrentEditId(record.id);
                            }}
                            style={{ marginTop: '8px' }}
                        />
                    </div>
                );
            },
        });
    }     

    const onSplitClose = () => { setEditingItem(false); onRefresh()}

    if (editingItem)
        divOper = <OrdersEdit onCancel={() => setEditingItem(false)} id={currentEditId} onClose = {onSplitClose}/>

    const tableLoading = {
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
    };

    return (
        <>
            <Toolbar title="Изделия" commands={commands} />
            <Table
                rowKey='posNumber'
                size='small'
                columns={columns}
                dataSource={items}
                loading={tableLoading}
                onRow={(record: IOrderItem) => {
                    return {
                        style: { cursor: 'pointer' },
                        onClick: () => {
                            if (!record.posNumber) return;
                            onCad(record.id);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `500px` }}
                rowClassName="order-item-row"
            />

            {openProductDialog && (<IzdCreate onCancel={() => setOpenProductDialog(false)} companyId={userSession.companyId} orderId={orderId} 
                onFinish={() => {
                    setOpenProductDialog(false);
                    onRefresh();
                }}/>)}

            {divOper}
        </>
    );
}

export default OrderItems;