import { Select } from "antd";
import React, { useEffect, useState } from "react";
import './com-select-color.css';
import { cadServerFetch } from "@src/core/server";

const ComSelectZapolnColor = ({elem, companyId, onChange}) => {
    const [colors, setColors] = useState([]);

    // Получить возможные цвета материала для материала заполнения
    useEffect(() => {
        if (elem.mat && elem.mat.id) {
            cadServerFetch(`settings/zapoln/mat/colors?companyId=${companyId}&matId=${elem.mat.id}`)
                .then(data => {
                    setColors(data);
                })
                .catch(() => console.log("Ошибка получения цветов материала заполнения"));
        }
    }, [elem.mat, companyId]);
    
    const onChangeColorId = (value) => {
        const newColor = colors.find(color => color.id === value);
        onChange({ ...elem, color: { id: newColor.id, name: newColor.name } });
    };

    const selectStyle = {width: 416};
    const colorOptions = colors.map(e => ({ value: e.id, label: e.name }))
    return<div className="com-selected-color">
        <Select placeholder="Цвет"
        options={colorOptions}
        value={elem.color.name}
        onChange={onChangeColorId}
        style={selectStyle}/>
    </div>
}

export default ComSelectZapolnColor;